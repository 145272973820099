// import React from 'react'
// import CoursesService from '../../services/CoursesService'
// import ModulesService from '../../services/ModulesService';
// import { useParams } from "react-router-dom"
// import { useEffect, useState } from "react";
// import withRouter from "../withRouter";
// import AdminService from '../../services/AdminService';
// import QuestionsService from "../../services/QuestionsService"

// import { Link } from 'react-router-dom';


// // import '../admin/Admin.css'
// function SaveQuestions(props) {
//     const [courselist, setCourseList] = useState([]);
//     const [modulelist, setModuleList] = useState([]);
//     const [courseId, setCourseId] = useState('')
//     const [question, setQuestion] = useState([])
//     const [questions, setQuestions] = useState([]);
//     const [msg, setMsg] = useState('')
//     const [isUpdating, setIsUpdating] = useState(false);
//     const [selectedQuestion, setSelectedQuestion] = useState(null);
//     // states for questions update
//     const [q, setQ] = useState('');
//     const [level, setLevel] = useState('');
//     const [module, setModule] = useState('');
//     const [status, setStatus] = useState('');
//     const [option1, setOption1] = useState('');
//     const [option2, setOption2] = useState('');
//     const [option3, setOption3] = useState('');
//     const [option4, setOption4] = useState('');
//     const [ranswer, setRAnswer] = useState('');

//     var courseBox = undefined;
//     var moduleBox = undefined;
//     var levelBox = undefined
//     var statusBox = undefined
//     var quesBox = undefined
//     var option1Box = undefined
//     var option2Box = undefined
//     var option3Box = undefined
//     var option4Box = undefined
//     var rightansBox = undefined

//     var save = (event) => {
//         event.preventDefault();
//         //  var course_id = courseBox.value
//         var moduleId = moduleBox.value
//         var quesLevel = levelBox.value
//         var active_status = statusBox.value
//         var question = quesBox.value
//         var option1 = option1Box.value
//         var option2 = option2Box.value
//         var option3 = option3Box.value
//         var option4 = option4Box.value
//         var answer = rightansBox.value

//         var ob = { moduleId, quesLevel, active_status, question, option1, option2, option3, option4, answer }
//         console.log(ob)
//         AdminService.addQuestion(ob)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data)
//                 setMsg(data.message)
//             })
//         event.target.reset();

//     }


//     const updateQuestionData = (event) => {
//         event.preventDefault();
//         if (selectedQuestion) {
//             var updatedQ = {
//                 ques_id: selectedQuestion,
//                 moduleId: module,
//                 quesLevel: level,
//                 active_status: status,
//                 que: q,
//                 option1: option1,
//                 option2: option2,
//                 option3: option3,
//                 option4: option4,
//                 ranswer: ranswer,
//             };

//             console.log(updatedQ);
//             AdminService.updateQuestion(selectedQuestion, updatedQ)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     console.log(data);
//                     setMsg("Question Updated..");

//                     // Update the questions directly in the state
//                     const updatedQuestions = questions.map((q) => {
//                         if (q.ques_id === selectedQuestion) {
//                             return { ...q, ...updatedQ };
//                         }
//                         return q;
//                     });
//                     setQuestions(updatedQuestions);

//                     // Clear the form fields and selectedQuestion
//                     setSelectedQuestion(null);
//                     setQ('');
//                     setLevel('');
//                     setModule('');
//                     setStatus('');
//                     setOption1('');
//                     setOption2('');
//                     setOption3('');
//                     setOption4('');
//                     setRAnswer('');
//                 })
//                 .catch((error) => {
//                     setMsg("Update failed..");
//                     // alert(error)
//                 });
//         }
//     };

//     const cancelUpdate = () => {
//         setSelectedQuestion(null);
//     };

//     let { ques_id } = useParams();
//     let { course_id } = useParams()
//     useEffect(() => {
//         // CoursesService.getAllCoursesList()
//         //     .then((response) => response.json())
//         //     .then((data) => {
//         //         console.log(data.course);
//         //         setCourseList(data.course.course);
//         //     });


//         // QuestionsService.getAllQuestion()
//         //     .then((response) => response.json())
//         //     .then((data) => {
//         //         console.log(data)
//         //         setQuestion(data.question.question)

//         //     })

//         if (ques_id) {
//             // Fetch the question data using the question ID
//             // You'll need to implement this method in your QuestionsService
//             QuestionsService.getQuestionById(ques_id)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     const questionData = data.question;
//                     setModule(questionData.module);
//                     setLevel(questionData.level);
//                     setStatus(questionData.status);
//                     setQuestion(questionData.q);
//                     setOption1(questionData.option1);
//                     setOption2(questionData.option2);
//                     setOption3(questionData.option3);
//                     setOption4(questionData.option4);
//                     setRAnswer(questionData.ranswer);
//                     setIsUpdating(true);
//                     // alert(questionData.q)
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//         }

//         ModulesService.getAllModules()
//             .then(response => response.json())
//             .then(data => {
//                 console.log(data.module.module);
//                 setModuleList(data.module.module)

//             })

//     }, [ques_id]);

//     // const handleUpdateClick = (ques_id) => {
//     //     setSelectedQuestion(ques_id); // update selectedQuestion state variable
//     //     // other code
//     //   };

//     const changeCourse = (event) => {
//         setCourseId(event.target.value)
//     }




//     return <>
//         <br></br>
//         <br></br>
//         <div class="container">
//             <center><h2>Save Questions</h2></center>
//             <br></br>

//             <form onSubmit={isUpdating ? updateQuestionData : save}>

//                 <div class='row col-lg-12 col-md-12' >




//                     <div className="col-lg-4 col-md-5 mb-2">
//                         <select className="form-control" ref={c => moduleBox = c} value={module} required onChange={e => setModule(e.target.value)}>
//                             <option value=''>Select Module...</option>


//                             {modulelist.map((ob, index) =>
//                                 <option value={ob.module_id}  >
//                                     {ob.module_title}
//                                 </option>

//                             )}



//                         </select>
//                     </div>
//                     <div className="col-lg-4 col-md-5 mb-2">
//                         <select className="form-control" ref={c => levelBox = c} value={level} onChange={e => setLevel(e.target.value)} required>
//                             <option value=''>Select Level</option>
//                             <option value='1'> Level - 1</option>
//                             <option value='2'> Level - 2</option>
//                             <option value='3'> Level - 3</option>
//                         </select>
//                     </div>

//                     <br></br>
//                     <br></br>
//                     {/* <div className="row col-lg-12"> */}
//                     <label className='mt-1'>Active_status</label>
//                     <div className="col-lg-2 col-md-2 mb-2">

//                         <select className="form-control" ref={c => statusBox = c} value={status} onChange={e => setStatus(e.target.value)} required>
//                             {/* <option value='' >Active_Status</option> */}
//                             <option value='1'>true</option>
//                             <option value='0'>false</option>
//                         </select>
//                     </div>

//                     {/* </div> */}
//                 </div>
//                 <br></br><br></br>
//                 <div class='col-lg-12'>
//                     <div class="form-group">
//                         {/* <label for="exampleFormControlInput1">Question</label> */}
//                         <input type="text" class="form-control" ref={c => quesBox = c} value={q} onChange={e => setQ(e.target.value)}
//                             id="exampleFormControlInput1" placeholder="Write question..... " required />
//                     </div>
//                     <div class="form-group">
//                         <input type="text" class="form-control" ref={c => option1Box = c} onChange={e => setOption1(e.target.value)} value={option1}
//                             id="exampleFormControlInput1" placeholder="a.Option A" />
//                     </div>
//                     <div class="form-group">
//                         <input type="text" class="form-control" ref={c => option2Box = c} onChange={e => setOption2(e.target.value)} value={option2} id="exampleFormControlInput1" placeholder="b.Option B" />
//                     </div>
//                     <div class="form-group">
//                         <input type="text" class="form-control" ref={c => option3Box = c} value={option3} onChange={e => setOption3(e.target.value)} id="exampleFormControlInput1" placeholder="c.Option C" />
//                     </div>
//                     <div class="form-group">
//                         <input type="text" class="form-control" ref={c => option4Box = c} onChange={e => setOption4(e.target.value)} value={option4} id="exampleFormControlInput1" placeholder="d.Option D" />
//                     </div>
//                     <div className='row'>
//                         <div class="form-group col-lg-6">
//                             <input type="text" class="form-control" ref={c => rightansBox = c} value={ranswer} onChange={e => setRAnswer(e.target.value)} id="exampleFormControlInput1" placeholder=" Select Correct Answer...(a,b,c,d)" required />
//                         </div>
//                         <div className="col-lg-4 col-md-4 mb-2">
//                             {isUpdating && question ? (
//                                 <>
//                                     <button
//                                         type="submit"
//                                         className="btn btn-success"
//                                         style={{ borderRadius: "10px" }}
//                                     >
//                                         Save
//                                     </button>
//                                     &nbsp;
//                                     <button
//                                         className="btn btn-secondary"
//                                         style={{ borderRadius: "10px" }}
//                                         onClick={cancelUpdate}
//                                     >
//                                         Cancel
//                                     </button>
//                                 </>
//                             ) : (
//                                 <button
//                                     type="submit"
//                                     className="btn btn-primary"
//                                     style={{ borderRadius: "10px" }}
//                                 >
//                                     Save
//                                 </button>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 &nbsp;
//                 <b className='text-danger'>{msg}</b>
//                 {/* <div className="col-lg-6 col-md-6">
//                     <button className="btn btn-success">Save</button>
//                     &nbsp;
//                     &nbsp;

//                     <Link class="nav-link" to="/questionsList"
//                         className="btn btn-success" >Questions List</Link>
//                 </div> */}
//             </form>
//         </div>

//         <br></br>
//         <br></br>


//     </>
// }
// export default withRouter(SaveQuestions);








import React from 'react'
import CoursesService from '../../services/CoursesService'
import ModulesService from '../../services/ModulesService';
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import withRouter from "../withRouter";
import AdminService from '../../services/AdminService';
import QuestionsService from "../../services/QuestionsService"

import { Link } from 'react-router-dom';


// import '../admin/Admin.css'
function SaveQuestions(props) {
    const [courselist, setCourseList] = useState([]);
    const [modulelist, setModuleList] = useState([]);
    const [courseId, setCourseId] = useState('')
    const [question, setQuestion] = useState([])
    const [msg, setMsg] = useState('')


    var courseBox = undefined;
    var moduleBox = undefined;
    var levelBox = undefined
    var statusBox = undefined
    var quesBox = undefined
    var option1Box = undefined
    var option2Box = undefined
    var option3Box = undefined
    var option4Box = undefined
    var rightansBox = undefined

    var save = (event) => {
        event.preventDefault();
        //  var course_id = courseBox.value
        var moduleId = moduleBox.value
        var quesLevel = levelBox.value
        var active_status = statusBox.value
        var question = quesBox.value
        var option1 = option1Box.value
        var option2 = option2Box.value
        var option3 = option3Box.value
        var option4 = option4Box.value
        var answer = rightansBox.value

        var ob = { moduleId, quesLevel, active_status, question, option1, option2, option3, option4, answer }
        // console.log(ob)
        AdminService.addQuestion(ob)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                setMsg(data.message)
            })
        event.target.reset();

    }

    let { course_id } = useParams()
    useEffect(() => {
        // CoursesService.getAllCoursesList()
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data.course);
        //         setCourseList(data.course.course);
        //     });


        // QuestionsService.getAllQuestion()
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data)
        //         setQuestion(data.question.question)

        //     })

        ModulesService.getAllModules()
            .then(response => response.json())
            .then(data => {
                // console.log(data.module.module);
                setModuleList(data.module.module)

            })

    }, []);



    const changeCourse = (event) => {
        setCourseId(event.target.value)
    }




    return <>
        <br></br>
        <br></br>
        <div class="container">
            <center><h3>Add Questions</h3></center>
            <br></br>

            <form onSubmit={save}>

                <div class='row col-lg-12 col-md-12' >




                    <div className="col-lg-4 col-md-5 mb-2">
                        <select className="form-control" ref={c => moduleBox = c} required>
                            <option value=''>Select Module...</option>


                            {modulelist.map((ob, index) =>
                                <option value={ob.module_id}  >
                                    {ob.module_title}
                                </option>

                            )}



                        </select>
                    </div>
                    <div className="col-lg-4 col-md-5 mb-2">
                        <select className="form-control" ref={c => levelBox = c} required>
                            <option value=''>Select Level</option>
                            <option value='1'> Level - 1</option>
                            <option value='2'> Level - 2</option>
                            <option value='3'> Level - 3</option>
                        </select>
                    </div>

                    <br></br>
                    <br></br>
                    {/* <div className="row col-lg-12"> */}
                    <label className='mt-1'>Active_status</label>
                    <div className="col-lg-2 col-md-2 mb-2">

                        <select className="form-control" ref={c => statusBox = c} required>
                            {/* <option value='' >Active_Status</option> */}
                            <option value='1'>true</option>
                            <option value='0'>false</option>
                        </select>
                    </div>

                    {/* </div> */}
                </div>
                <br></br><br></br>
                <div class='col-lg-12'>
                    <div class="form-group">
                        {/* <label for="exampleFormControlInput1">Question</label> */}
                        <input type="text" class="form-control" ref={c => quesBox = c}
                            id="exampleFormControlInput1" placeholder="Write question..... " required />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" ref={c => option1Box = c}
                            id="exampleFormControlInput1" placeholder="a.Option A" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" ref={c => option2Box = c} id="exampleFormControlInput1" placeholder="b.Option B" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" ref={c => option3Box = c} id="exampleFormControlInput1" placeholder="c.Option C" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" ref={c => option4Box = c} id="exampleFormControlInput1" placeholder="d.Option D" />
                    </div>
                    <div className='row'>
                        <div class="form-group col-lg-6">
                            <input type="text" class="form-control" ref={c => rightansBox = c} id="exampleFormControlInput1" placeholder=" Select Correct Answer...(a,b,c,d)" required />
                        </div>
                        <div className="col-lg-4 col-md-4 mb-2">
                            <button className="btn btn-green">Save</button>


                        </div>
                    </div>
                </div>
                &nbsp;
                <b className='text-danger'>{msg}</b>
                {/* <div className="col-lg-6 col-md-6">
                    <button className="btn btn-success">Save</button>
                    &nbsp;
                    &nbsp;

                    <Link class="nav-link" to="/questionsList"
                        className="btn btn-success" >Questions List</Link>
                </div> */}
            </form>
        </div>

        <br></br>
        <br></br>


    </>
}
export default withRouter(SaveQuestions);
