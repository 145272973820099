import React from 'react'
import QuestionsService from '../../services/QuestionsService'
import TestService from '../../services/TestService'
import withRouter from '../withRouter'
import { Link } from 'react-router-dom'
import './QuestionItem.css'
import QusItem from './QusItem'
import { useEffect, useState } from 'react'
// import { configureStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import ModulesService from '../../services/ModulesService'
import { useNavigate } from 'react-router-dom';


function Question(props) {
  const loginUser = useSelector(state => state.user.value)
  // const testans = useSelector(state => state.user.value)
  // var dispatch = useDispatch()

  const [questions, setQuestions] = useState([])
  const [test, setTest] = useState([])
  const [answer, setAnswer] = useState([])
  const [module, setModule] = useState([])
  const [questionsNotFound, setQuestionsNotFound] = useState(false)
  const [validationError, setValidationError] = useState('');
  const navigate = useNavigate();

  //  let {id} =useParams();

  var onSave = () => {
    // console.log("+++++++++++++",test)

    if (answer.length !== questions.length) {
      setValidationError('Answer all questions before submitting.');
      return;
    }

    var currentDate = new Date()

    const userid = loginUser.userId
    const moduleId = props.params.moduleId
    const quesLevel = props.params.quesLevel
    const date =
      currentDate.getFullYear() +
      '/' +
      (currentDate.getMonth() + 1) +
      '/' +
      currentDate.getDate()
    const totalQ = questions.length
    // console.log("date", date)
    // console.log("userid", userid)

    // console.log(userid, moduleId, quesLevel, date)
    // var t=setTest(test)
    TestService.saveTestByUserId(
      userid,
      moduleId,
      quesLevel,
      date,
      answer,
      totalQ
    )
      .then(response => response.json())
      .then(data => {
        setTest(data.test)
        navigate('/success');
        // console.log(data.test)
      })
  }

  var submitAnswer = (qid, answers, correctAns, totalQ) => {
    // alert(qid + answers)
    var ans = answer.find(ob => ob.qid == qid)
    if (ans == undefined)
      var arr = [...answer, { qid, answers, correctAns, totalQ }]
    else
      var arr = answer.map(ob =>
        ob.qid == qid ? { qid, answers, correctAns, totalQ } : ob
      )
    setAnswer(arr)
  }


  useEffect(() => {
    const quesLevel = props.params.quesLevel
    const moduleId = props.params.moduleId
    const userid = loginUser.userId

    QuestionsService.getQuestionById(quesLevel, moduleId, userid)
      .then(response => response.json())
      .then(data => {
        if (data.question.question.length > 0) {
          setQuestions(data.question.question)
        } else {
          setQuestionsNotFound(true)
        }
      })

    const module_id = props.params.moduleId
    ModulesService.getModuleById(module_id)
      .then(response => response.json())
      .then(data => {
        setModule(data.module.data)
      })
  }, [])


  return (
    <>
      <div>
        <br></br>
        <br></br>
        {/* <div className=' col-lg-12 col-md-12 col-sm-12 col-12 '> */}

        <div className=' container'>
          {/* <div className='row'> */}
          <div className=' col-lg-12 col-md-12 col-sm-12'>


            {/* <form onSubmit={onSave}> */}
            <form>
              <div className=' module sticky-top '>
                MODULE : &nbsp;
                {module.map(ob => ob.module_title)}
                <div className='queqty'>Total:&nbsp; {questions.length}</div>
              </div>
              {/* {this.state.questions.map((ques, index) => (
                        <QuestionItem key={index + 1} question={ques} />
                      ))} */}
              {/* {questions.map((ques, index) => <QusItem qus={ques}  index={index+1} />)} */}
              {questions.length > 0 ? (
                questions.map((ques, index) => (
                  <QusItem
                    key={index}
                    qus={ques}
                    index={index + 1}
                    submitAnswer={submitAnswer}
                  />
                ))
              ) : questionsNotFound ? (
                <h2 className='text-center'>Questions may be updated soon. Please check back later.</h2>
              ) : (
                <h4 className='text-center'>Loading...</h4>
              )}
            </form>
          </div>
          {/* </div> */}
        </div>
        {questions.length > 0 && (
          <div className='submitbtn fixed-bottom text-center mt-3'>
            <button className='btn btn-green' onClick={onSave}>
              Submit
            </button>

          </div>
        )}
        {validationError && (
          <div className="text-center mt-3">
            <h5 className="text-danger">{validationError}</h5>
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(Question)
