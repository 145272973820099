// import React, { useEffect, useState } from "react";
// import AdminService from "../../services/AdminService";
// import withRouter from "../withRouter";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import HrService from "../../services/HrService";

// function ViewStudentDetails({ props }) {
//     const loginUser = useSelector((state) => state.user.value);
//     const [user, setUser] = useState([]);
//     const [favorites, setFavorites] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [searchValue, setSearchValue] = useState("");
//     const [isLoading, setIsLoading] = useState(false);
//     const itemsPerPage = 10;
//     const [showFavoritesTable, setShowFavoritesTable] = useState(false);
//     const navigate = useNavigate();

//     const result = (userid) => {
//         navigate("/StudentTestRecord/" + userid);
//     };

//     // const addFavorite = (hruserid, candidate_id) => {
//     //     setIsLoading(true);
//     //     HrService.addFavorite({ hruserid, candidate_id })
//     //         .then((response) => response.json())
//     //         .then((data) => {
//     //             if (data.success) {
//     //                 alert(data.message);
//     //                 setFavorites((prevFavorites) => [...prevFavorites, data.favorite]);
//     //                 setIsLoading(false);
//     //             } else {
//     //                 alert("Already added to Favourites.");
//     //                 setIsLoading(false);
//     //             }
//     //         })
//     //         .catch((error) => {
//     //             console.error("Error adding favorite:", error);
//     //             alert("Error adding favorite.");
//     //             setIsLoading(false);
//     //         });
//     // };

//     const addFavorite = (hruserid, candidate_id) => {
//         setIsLoading(true);
//         // Optimistically update the UI
//         setUser(prevUser =>
//             prevUser.map(user =>
//                 user.userid === candidate_id ? { ...user, favstatus: true } : user
//             )
//         );

//         HrService.addFavorite({ hruserid, candidate_id })
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data.success) {
//                     alert(data.message);
//                     setFavorites((prevFavorites) => [...prevFavorites, data.favorite]);
//                 } else {
//                     alert("Already added to Favourites.");

//                     setUser(prevUser =>
//                         prevUser.map(user =>
//                             user.userid === candidate_id ? { ...user, favstatus: false } : user
//                         )
//                     );
//                 }
//             })
//             .catch((error) => {
//                 console.error("Error adding favorite:", error);
//                 alert("Error adding favorite.");
//                 // Roll back the optimistic update
//                 setUser(prevUser =>
//                     prevUser.map(user =>
//                         user.userid === candidate_id ? { ...user, favstatus: false } : user
//                     )
//                 );
//             })
//             .finally(() => {
//                 setIsLoading(false);
//             });
//     };


//     // const removeFav = async (favorite) => {
//     //     const confirmDelete = window.confirm("Are you sure you want to Remove from Favorites?");
//     //     if (confirmDelete) {
//     //         setIsLoading(true);
//     //         await HrService.dleteFav(favorite.id, favorite.userid)
//     //             .then((response) => response.json())
//     //             .then((data) => {
//     //                 if (data.success) {
//     //                     alert(data.message);
//     //                     setFavorites((prevFavorites) => prevFavorites.filter((fav) => fav.id !== favorite.id));
//     //                     setIsLoading(false);
//     //                     handleSearch(searchValue)
//     //                 } else {
//     //                     alert(data.message);
//     //                     setIsLoading(false);
//     //                 }
//     //             })
//     //             .catch((error) => {
//     //                 console.error("Error removing favorite:", error);
//     //                 alert("Error removing favorite.");
//     //                 setIsLoading(false);
//     //             });
//     //     }
//     // };


//     const removeFav = async (favorite) => {
//         const confirmDelete = window.confirm("Are you sure you want to Remove from Favorites?");
//         if (confirmDelete) {
//             setIsLoading(true);
//             // Optimistically update the UI
//             setFavorites((prevFavorites) => prevFavorites.filter((fav) => fav.id !== favorite.id));
//             setUser(prevUser =>
//                 prevUser.map(user =>
//                     user.userid === favorite.userid ? { ...user, favstatus: false } : user
//                 )
//             );

//             await HrService.dleteFav(favorite.id, favorite.userid)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     if (data.success) {
//                         alert(data.message);
//                     } else {
//                         alert(data.message);

//                         setFavorites((prevFavorites) => [...prevFavorites, favorite]);
//                         setUser(prevUser =>
//                             prevUser.map(user =>
//                                 user.userid === favorite.userid ? { ...user, favstatus: true } : user
//                             )
//                         );
//                     }
//                 })
//                 .catch((error) => {
//                     console.error("Error removing favorite:", error);
//                     alert("Error removing favorite.");
//                     // Roll back the optimistic update
//                     setFavorites((prevFavorites) => [...prevFavorites, favorite]);
//                     setUser(prevUser =>
//                         prevUser.map(user =>
//                             user.userid === favorite.userid ? { ...user, favstatus: true } : user
//                         )
//                     );
//                 })
//                 .finally(() => {
//                     setIsLoading(false);
//                 });
//         }
//     };

//     const handleSearch = () => {
//         setIsLoading(true);
//         AdminService.searchUser(searchValue)
//             .then((response) => response.json())
//             .then((data) => {
//                 if (data && data.user && data.user.user && data.user.user.length > 0) {
//                     setUser(data.user.user);
//                     setShowFavoritesTable(false); // Hide favorites table on successful search
//                 } else {
//                     setUser([]);
//                 }
//                 setIsLoading(false);
//             })
//             .catch((error) => {
//                 console.error("Error searching users:", error);
//                 setUser([]);
//                 setIsLoading(false);
//             });
//     };

//     const handleCancelSearch = () => {
//         setSearchValue("");
//         setUser([]); // Clear user list on cancel
//         setShowFavoritesTable(false); // Hide favorites table on cancel
//         setIsLoading(false); // Reset loading state
//     };

//     const favourts = async (hruserid) => {
//         setIsLoading(true);
//         try {
//             const response = await HrService.favourtList(hruserid);
//             if (response && response.user && response.user.user && response.user.user.length > 0) {
//                 setFavorites(response.user.user);
//                 setShowFavoritesTable(true);
//                 setUser([]);
//             } else {
//                 setFavorites([]);
//                 setShowFavoritesTable(false);
//             }
//             setIsLoading(false);
//         } catch (error) {
//             console.error("Error fetching favorites:", error);
//             setFavorites([]);
//             setIsLoading(false);
//         }
//     };

//     const handleSearchChange = (event) => {
//         setSearchValue(event.target.value);
//     };

//     return (
//         <>
//             <br />
//             <br />
//             <div className="container">
//                 <center>
//                     <h3>Students List</h3>
//                 </center>
//                 <br />
//                 <div className="form-group row">
//                     <div className="col-sm-3 col-lg-3 col-md-3">
//                         <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Search by Name/Email/Contact"
//                             value={searchValue}
//                             onChange={handleSearchChange}
//                         />
//                     </div>
//                     <div className="col-sm-3 col-lg-3 col-md-3">
//                         <button className="btn btn-primary" onClick={handleSearch}>
//                             Search
//                         </button>
//                         &nbsp;
//                         <button className="btn btn-secondary" onClick={() => favourts(loginUser.userId)}>
//                             My List
//                         </button>
//                         {searchValue && (
//                             <button className="btn btn-danger ml-2" onClick={handleCancelSearch}>
//                                 Cancel
//                             </button>
//                         )}
//                     </div>
//                 </div>
//                 {isLoading ? (
//                     <div className="text-center">
//                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                         <span> Loading...</span>
//                     </div>
//                 ) : (
//                     <>
//                         {user?.length > 0 && !showFavoritesTable && (
//                             <div className="table-responsive">
//                                 <table className="table table-bordered">
//                                     <thead>
//                                         <tr className="text-center">
//                                             <th>S.no</th>
//                                             <th>Name</th>
//                                             <th>Education</th>
//                                             <th>Contact</th>
//                                             <th>Email</th>
//                                             <th>Test</th>
//                                             <th>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {user?.map((ob, index) => (
//                                             <tr key={index}>
//                                                 <td>{index + 1}</td>
//                                                 <td>{ob.name}</td>
//                                                 <td>{ob.education}</td>
//                                                 <td>{ob.contact}</td>
//                                                 <td>{ob.email}</td>
//                                                 <td>
//                                                     <button
//                                                         className="btn btn-center btn-sm btn-primary"
//                                                         style={{ borderRadius: "10px" }}
//                                                         onClick={() => result(ob.userid)}
//                                                     >
//                                                         Test History
//                                                     </button>
//                                                 </td>
//                                                 <td>
//                                                     <div className="d-flex">
//                                                         {ob.favstatus ? (
//                                                             <button
//                                                                 className="btn btn-sm btn-secondary"
//                                                                 style={{ borderRadius: "10px" }}
//                                                                 onClick={() => removeFav(ob)}
//                                                             >
//                                                                 Remove Fav.
//                                                             </button>
//                                                         ) : (
//                                                             <button
//                                                                 className="btn btn-sm btn-secondary"
//                                                                 style={{ borderRadius: "10px" }}
//                                                                 onClick={() => addFavorite(loginUser.userId, ob.userid)}
//                                                             >
//                                                                 Make Fav.
//                                                             </button>
//                                                         )}
//                                                     </div>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         )}
//                         {showFavoritesTable && favorites?.length > 0 && (
//                             <div className="table-responsive">
//                                 <table className="table table-bordered">
//                                     <thead>
//                                         <tr className="text-center">
//                                             <th>S.no</th>
//                                             <th>Name</th>
//                                             <th>Education</th>
//                                             <th>Contact</th>
//                                             <th>Email</th>
//                                             <th>Test</th>
//                                             <th>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {favorites?.map((fav, index) => (
//                                             <tr key={index}>
//                                                 <td>{index + 1}</td>
//                                                 <td>{fav.name}</td>
//                                                 <td>{fav.education}</td>
//                                                 <td>{fav.contact}</td>
//                                                 <td>{fav.email}</td>
//                                                 <td>
//                                                     <button
//                                                         className="btn btn-center btn-sm btn-primary"
//                                                         style={{ borderRadius: "10px" }}
//                                                         onClick={() => result(fav.userid)}
//                                                     >
//                                                         Test History
//                                                     </button>
//                                                 </td>
//                                                 <td>
//                                                     <div className="d-flex">
//                                                         <button
//                                                             className="btn btn-sm btn-secondary"
//                                                             style={{ borderRadius: "10px" }}
//                                                             onClick={() => removeFav(fav)}
//                                                         >
//                                                             Remove Fav.
//                                                         </button>
//                                                     </div>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         )}
//                         {user?.length === 0 && favorites?.length === 0 && (
//                             <div className="text-center">
//                                 <p>No Records Found </p>
//                             </div>
//                         )}
//                     </>
//                 )}
//             </div>
//         </>
//     );
// }

// export default withRouter(ViewStudentDetails);


import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HrService from "../../services/HrService";

function ViewStudentDetails({ props }) {
    const loginUser = useSelector((state) => state.user.value);
    const hr = useState(loginUser.userId)
    const [user, setUser] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const itemsPerPage = 10;
    const [showFavoritesTable, setShowFavoritesTable] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const navigate = useNavigate();
    const result = (userid) => {
        navigate("/StudentTestRecord/" + userid);
    };



    const removeFav = async (favorite) => {
        const confirmDelete = window.confirm("Are you sure you want to Remove from Favorites?");
        if (confirmDelete) {
            setIsLoading(true);
            // Optimistically update the UI
            setFavorites((prevFavorites) => prevFavorites.filter((fav) => fav.id !== favorite.id));
            setUser(prevUser =>
                prevUser.map(user =>
                    user.userid === favorite.userid ? { ...user, favstatus: false } : user
                )
            );

            await HrService.dleteFav(favorite.id, favorite.userid)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert(data.message);
                    } else {
                        alert(data.message);

                        setFavorites((prevFavorites) => [...prevFavorites, favorite]);
                        setUser(prevUser =>
                            prevUser.map(user =>
                                user.userid === favorite.userid ? { ...user, favstatus: true } : user
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error removing favorite:", error);
                    alert("Error removing favorite.");
                    // Roll back the optimistic update
                    setFavorites((prevFavorites) => [...prevFavorites, favorite]);
                    setUser(prevUser =>
                        prevUser.map(user =>
                            user.userid === favorite.userid ? { ...user, favstatus: true } : user
                        )
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleSearch = () => {
        if (!searchValue) {
            setShowValidationMessage(true);
            return;
        }
        setIsLoading(true);
        HrService.searchFavoriteUser(searchValue)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.user && data.user.user && data.user.user.length > 0) {
                    setFavorites(data.user.user);
                    setShowFavoritesTable(false); // Hide favorites table on successful search
                } else {
                    setUser([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error searching users:", error);
                setUser([]);
                setIsLoading(false);
            });
    };

    const handleCancelSearch = () => {
        setSearchValue("");
        favourts(hr)
        setUser([]); // Clear user list on cancel
        setShowFavoritesTable(false); // Hide favorites table on cancel
        setIsLoading(false); // Reset loading state
        setShowValidationMessage(false);

    };


    const favourts = async (hr) => {
        setIsLoading(true);
        try {
            const response = await HrService.favourtList(hr);
            if (response && response.user && response.user.user && response.user.user.length > 0) {
                setFavorites(response.user.user);

                setUser([]);
            } else {
                setFavorites([]);
                setShowFavoritesTable(false);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching favorites:", error);
            setFavorites([]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        favourts(hr)
    }, [])

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        if (event.target.value) {
            setShowValidationMessage(false); // Hide validation message when user starts typing
        }
    };

    return (
        <>
            <br />
            <br />
            <div className="container">
                <center>
                    <h3>Candidate's List</h3>
                </center>
                <br />
                <div className="form-group row">
                    <div className="col-sm-3 col-lg-3 col-md-3 text-right">
                        <input
                            type="text"
                            className={`form-control ${showValidationMessage ? 'is-invalid' : ''}`}
                            placeholder="Search by Email Or Contact"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                        {showValidationMessage && (
                            <div className="invalid-feedback">
                                Please enter a search term.
                            </div>
                        )}
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <button className="btn btn-green" onClick={handleSearch}>
                            Search
                        </button>
                        &nbsp;

                        {searchValue && (
                            <button className="btn btn-danger ml-2" onClick={handleCancelSearch}>
                                Cancel
                            </button>
                        )}
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3"></div>
                    <div className="col-sm-3 col-lg-3 col-md-3 text-right">
                        <Link to="/addFavorite"><button className="btn btn-green" >
                            Add New                        </button></Link>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-center">
                                <th>S.no</th>
                                <th>Name</th>
                                <th>Education</th>
                                <th>Contact</th>
                                <th>Email</th>
                                <th>Test</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {favorites?.map((fav, index) => (
                                <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{fav.name}</td>
                                    <td>{fav.education}</td>
                                    <td>{fav.contact}</td>
                                    <td>{fav.email}</td>
                                    <td>
                                        <button
                                            className="btn btn-center btn-sm btn-green"
                                            style={{ borderRadius: "10px" }}
                                            onClick={() => result(fav.userid)}
                                        >
                                            Test History
                                        </button>
                                    </td>
                                    <td>

                                        <button
                                            className="btn btn-sm btn-secondary"
                                            style={{ borderRadius: "10px" }}
                                            onClick={() => removeFav(fav)}
                                        >
                                            Remove Fav.
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {user?.length === 0 && favorites?.length === 0 && (
                    <div className="text-center">
                        <p>No Records Found </p>
                    </div>
                )}

            </div>

        </>
    );
}

export default withRouter(ViewStudentDetails);