import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeData } from '../reduxdata/UserSlice';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import LoginService from '../services/LoginService';
import { jwtDecode } from 'jwt-decode';
// import './Menu.css'

export default function Menu() {
  const loginUser = useSelector(state => state.user.value);
  const resumeData = useSelector(state => state.user.resumeVal);
  console.log(resumeData);
  const d = localStorage.getItem("resumeData");
  console.log(d);

  const [loginCheck, setLoginCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(
      changeData({ username: undefined, token: undefined, islogin: false, role: undefined })
    );
    setLoginCheck(true);
  };

  // const resumeLogin = () => {
  //   LoginService.LoginUserTwo(resumeData || d)
  //     .then(response => {
  //       console.log("response", response)
  //       // Check if the response status is OK
  //       // if (response.status == 302) {
  //       // Redirect to the external URL directly
  //       // window.location.href = "https://resume-builder.skilledfresher.in";
  //       // navigate('/')
  //       // } else {
  //       //   // Handle response errors
  //       //   return response.json().then(data => {
  //       //     console.error('Login failed:', data.message);
  //       //   });
  //       // }
  //     })
  //     .catch(error => {
  //       console.error('Error during resume login:', error);
  //     });
  // };

  const resumeLogin = () => {
    const dataToSend = resumeData || d;
    if (!dataToSend) {
      console.error('No resume data available');
      return;
    }

    LoginService.LoginUserTwo(dataToSend)
      .then(response => {
        console.log("Login Response:", response);
        // Handle successful login
        if (response && response.access) {
          // Redirect to the desired page
          window.location.href = "https://resume-builder.skilledfresher.in";
        } else {
          // Handle response errors
          console.error('Login failed:', response.message);
           window.location.href = "https://resume-builder.skilledfresher.in/ulogin";
        }
      })
      .catch(error => {
        console.error('Error during resume login:', error);
      });
  };


  if (loginCheck) {
    setLoginCheck(false)
    return <Navigate to="/login" />;
  } else
    return <>
      <div>
        <a class="close-navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

        </a>
        <nav className="navbar navbar-expand-sm navbar-light">
          {/* <div className="container-fluid"> */}
          {/* <Link className="navbar-brand" to="/">
         
            <img src='images/logo.jpg' height='100px' width='auto' style={{ background: "transparent" }} />
          </Link> */}

          <Link className="navbar-brand header" to="/">
            {/* <i className="uil uil-user" /> CodeBetter */}
            {/* <div className="image-title-container logo" style={{ display: "flex", alignItems: "center" }}> 
              <img src="images/skilledfresher.png" height="60px" width="60px" style={{ background: "transparent" }} alt="SkilledFresher Logo" />
              <h3 className="" style={{ marginLeft: "10px" }}>SkilledFresher.in</h3>
             
            </div> */}
            <div class="container d-flex align-items-center pl-1 p-0">
              <div class="row mr-5">
                <div class="col logo " ><img src="./images/skilledfresher.png" alt="" /> </div>
                <div class="col text-center pl-0 ml-1" ><h1 class="logo mr-auto pb-0 mb-0 text-left " >SkilledFresher.<span>in</span></h1><p className='logo-text'>stand up for what you know</p></div>
              </div>
            </div>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
            <span className="navbar-toggler-icon" />
            <span className="navbar-toggler-icon" />
          </button>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mx-auto">
              {loginUser.islogin ? <>


                {loginUser.role == 'student' || loginUser.role == 'admin' || loginUser.role == 'hr' ?
                  <li class="nav-item">
                    {/* <Link class="nav-link" to="/">Home</Link> */}
                    <a class="nav-link" href='http://skilledfresher.in/' target='blank'>Home</a>
                  </li>
                  : ""}

                {loginUser.role == 'admin' ? <>
                  <li class="nav-item">
                    <div class="dropdown show ">
                      <Link class="btn  dropdown-toggle nav-link" to="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Course
                      </Link>
                      <div class="dropdown-menu " aria-labelledby="dropdownMenuLink">
                        <Link to="/saveCourse" class="dropdown-item " >Add-Course</Link>
                        <Link class="dropdown-item" to="/saveModules">Add-Modules</Link>
                        <Link class="dropdown-item " to="/saveCourseModules">Add-CourseModules</Link>
                      </div>
                    </div>
                  </li>
                  &nbsp;&nbsp;
                  <li class="nav-item">
                    <div class="dropdown show">
                      <Link class="btn  dropdown-toggle nav-link" to="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Questions
                      </Link>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <Link class="dropdown-item " to="/saveQuestions">Add-Questions</Link>
                        <Link class="dropdown-item " to="/questionsList">View-Questions</Link>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/user">User</Link>
                  </li>
                  <li class="nav-item">
                    <Link to='/' ><b style={{ cursor: 'pointer' }} onClick={logout} class="nav-link">LogOut</b></Link>
                  </li>
                </> : <></>}

                {loginUser.role == 'student' ? <>
                  <li class="nav-item">
                    <Link class="nav-link" to="/course">Skills</Link>
                  </li>
                  <li class="nav-item">
                    {/* <a class="nav-link" target='blank' href='http://195.35.20.207:8099/signin'>Resume</a> */}
                    <b style={{ cursor: 'pointer' }} onClick={resumeLogin} class="nav-link">Resume</b>
                    {/* <a class="nav-link" target='blank' href='http://195.35.20.207:8099/'>Resume</a> */}
                    {/* <b style={{ cursor: 'pointer' }} class="nav-link">Resume</b> */}
                  </li>

                  <li class="nav-item">
                    <Link class="nav-link" to="/contact">Contact</Link>
                  </li>

                  <li class="nav-item">
                    <Link to='/testhistory' ><b style={{ cursor: 'pointer' }} class="nav-link">Test</b></Link>
                  </li>

                  <li class="nav-item">
                    <Link to='/' ><b style={{ cursor: 'pointer' }} onClick={logout} class="nav-link">LogOut</b></Link>
                  </li>
                </> : <></>}


                {loginUser.role == 'hr' ? <>
                  {/* <li class="nav-item">
                    <Link to='/' ><b style={{ cursor: 'pointer' }} class="nav-link">Home</b></Link>
                  </li> */}
                  <li class="nav-item">
                    <Link to='/studentDetail' ><b style={{ cursor: 'pointer', textWrap: 'noWrap' }} class="nav-link" >Candidates</b></Link>
                  </li>
                </>

                  : ""}

                {loginUser.role == 'student' || loginUser.role == 'admin' || loginUser.role == 'hr' ? <>
                  {/* <li class="nav-item">
                    <Link to='/' ><b style={{ cursor: 'pointer' }} onClick={logout} class="nav-link">LogOut</b></Link>
                  </li> */}

                  {/* <li class="nav-item " style={{ cursor: 'pointer', marginLeft: '200px', color: 'red' }}>
                    <Link to='/setting' ><b style={{ cursor: 'pointer' }} class="nav-link ico"><i className="uil uil-cog size='xl'" />:&nbsp;{loginUser.username}</b></Link>
                  </li> */}
                  <li className="nav-item dropdown" style={{ cursor: 'pointer', marginLeft: '200px', color: 'red' }}>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="uil uil-cog" />
                      &nbsp;{loginUser.username}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="userDropdown">
                      <Link className="dropdown-item" to="/myProfile">
                        Profile
                      </Link>
                      <Link className="dropdown-item" to="/changePassword">
                        Change Password
                      </Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/" onClick={logout}>
                        Logout
                      </Link>
                    </div>
                  </li>

                </> : ""}
              </> : <>
                <li class="nav-item">
                  <a class="nav-link" href='http://skilledfresher.in/'>Home</a>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/course">Skills</Link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" target='blank' href='https://resume-builder.skilledfresher.in'>Resume</a> 
{/* <b style={{ cursor: 'pointer' }} onClick={resumeLogin} class="nav-link">Resume</b>*/}
                </li>

                <li class="nav-item">
                  <Link class="nav-link" to="/contact">Contact</Link>
                </li>

                <li class="nav-item">
                  <Link class="nav-link" to="/register">Register</Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/login">Login</Link>
                </li>
              </>}
            </ul>
          </div>
          {/* </div> */}
        </nav >
      </div >
    </>
}
