import {createSlice} from '@reduxjs/toolkit';

export const testSlice = createSlice ({
  name: 'test',
  initialState: {
    value: {
      
      testid:0

    },
  },
  reducers: {
    changeTestData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {changeTestData} = testSlice.actions;
export default testSlice.reducer;