import React from "react";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import { useEffect, useState } from "react";
import withRouter from "../withRouter";
import ModulesService from "../../services/ModulesService";

function SaveCourseModules(props) {
  const [getmodule, setGetModule] = useState([]);
  const [getcourse, setGetCourse] = useState([]);
  const [getcoursemodule, setGetCourseModule] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [msg, setMsg] = useState('')
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedCM, setSelectedCM] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [moduleTitle, setModuleTitle] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);


  var courseBox = undefined;
  var moduleBox = undefined;

  // console.log(course_title,description,active_status)
  const save = (event) => {
    event.preventDefault();
    var course = courseBox.value;
    var module = moduleBox.value;

    // console.log(course_title, description, active_status);
    var ob = { course, module };
    // console.log(ob);
    AdminService.addCoursesModules(ob)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setMsg("CourseModule Saved..")


      });
    event.target.reset();
  };


  const delcoursemodule = (course_module_id) => {
    // console.log("sv", course_module_id)
    const confirmDelete = window.confirm("Are you sure you want to delete this course with module?");
    if (confirmDelete) {
      AdminService.deletecourseModule(course_module_id)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          setMsg("Course Deleted..");
          // Update the course list after deletion
          setGetCourseModule((prevCoursemoduleList) => prevCoursemoduleList.filter(coursemodule => coursemodule._id !== course_module_id));
          setIsDataUpdated(!isDataUpdated); // Trigger data update
        });
    }
  };

  const updateCMData = (event) => {
    event.preventDefault();
    if (selectedCM) {
      var updatedCM = {
        course_module_id: selectedCM.course_module_id,
        course: courseTitle,
        module: moduleTitle,
      };

      // console.log(updatedCM);
      AdminService.updateCourseModule(selectedCM.course_module_id, updatedCM)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          setMsg("Course Module Updated..");
          // Update the course directly in the state
          setGetCourseModule((prevCourseList) =>
            prevCourseList.map((coursemodule) =>
              coursemodule.course_module_id === selectedCM.course_module_id ? data : coursemodule
            )
          );
          setIsDataUpdated(!isDataUpdated); // Trigger data update

          setSelectedCM(null);
          setCourseTitle("");
          setModuleTitle("");

          // Assign the updated course to the data variable
          data = {
            ...selectedCM,
            course: courseTitle,
            module: moduleTitle
          };

          // console.log(data);
        })
        .catch((error) => {
          setMsg("Update failed..");
        });
    }
  }


  const cancelUpdate = () => {
    setSelectedCM(null);
    setCourseTitle("");
    setModuleTitle("");
    setIsUpdating(false);
  };

  const upCourse = (coursemodule) => {
    setSelectedCM(coursemodule);
    setCourseTitle(coursemodule.course);
    setModuleTitle(coursemodule.module);
    setIsUpdating(true);
  };


  useEffect(() => {
    if (isDataUpdated) {
      setMsg("");
      setSelectedCM(null);
      setCourseTitle("");
      setModuleTitle("");
      setIsUpdating(false);
    }
  }, [isDataUpdated]);

  useEffect(() => {
    CoursesService.getAllCourses()
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.course);
        setGetCourse(data.course.course);
      });

    ModulesService.getAllModules()
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.module);
        setGetModule(data.module.module);
      });
    CoursesService.getAllCoursesModule()
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.courses_modules);
        setGetCourseModule(data.courses_modules.course_modules)
      });



  }, [isDataUpdated]);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getcoursemodule.slice(indexOfFirstItem, indexOfLastItem);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(getcoursemodule.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };


  return (
    <>
      <br></br>
      <br></br>
      <div className="container">




        <form onSubmit={isUpdating ? updateCMData : save}>
          <center><h3>Add Course Modules</h3></center>
          <br></br>
          <br></br>
          <div class="row col-lg-12">
            <div className="col-lg-5 col-md-5">
              <select
                className="form-control" value={courseTitle} onChange={(event) => setCourseTitle(event.target.value)}
                ref={(c) => (courseBox = c)} id="courseBox" name="coursebox"
                required
              >
                <option value="">Select Course...</option>

                {getcourse.map((ob, index) => <option value={ob.course_id} key={index}>
                  {ob.course_title}

                </option>)}
              </select>
            </div>

            <div className="col-lg-5 col-md-5">
              <select
                className="form-control"
                ref={(c) => (moduleBox = c)} value={moduleTitle} onChange={(event) => setModuleTitle(event.target.value)} id="moduleBox" name="modulebox"
                required
              >
                <option value="">Select Module...</option>

                {getmodule.map((ob, index) => <option value={ob.module_id} key={index}>
                  {ob.module_title}

                </option>)}
              </select>
            </div>
            <div className="col-sm-2 col-lg-2 col-md-2 ">
              {isUpdating && selectedCM ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ borderRadius: "10px" }}
                  >
                    Save
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-secondary"
                    style={{ borderRadius: "10px" }}
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className="btn btn-green"
                  style={{ borderRadius: "10px" }}
                >
                  Save
                </button>
              )}
            </div>

          </div>
          <br></br>
          <div className="form-group row">
            &nbsp;<b className='text-danger'>{msg}</b>
          </div>
        </form>
      </div>

      <br />
      <div className="container">
        <div className="table-responsive">

          <table className="table table-bordered  ">
            <thead>
              <tr className="text-center">
                <th>S.no</th>
                <th>Course </th>
                <th>Modules</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((ob, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{ob.course_title}</td>
                  <td>{ob.module_title}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={() => upCourse(ob)}
                    >
                      Update
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-sm btn-danger"
                      style={{ borderRadius: "10px" }}
                      onClick={() => delcoursemodule(ob.course_module_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="container d-flex justify-content-between pagination-container">
          <button type="button" className="btn btn-dark" onClick={goToPreviousPage} disabled={currentPage === 1}>
            &larr; Previous
          </button>
          {/* <div>Page {currentPage}</div> */}
          <div>
            {getPageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                type="button"
                className={`btn btn-dark mr-1 ${currentPage === pageNumber ? 'active' : ''}`}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
          </div>
          <button type="button" className="btn btn-dark" onClick={goToNextPage} disabled={indexOfLastItem >= getcoursemodule.length}>
            Next &rarr;
          </button>
        </div>
      </div>
    </>
  );
}
export default withRouter(SaveCourseModules);
