// import React, { Component } from 'react'
// import './ModuleItem.css'
// import { Link } from 'react-router-dom'

// export default function ModuleItem(props) {
//   return (
//     <>
//       {/* <div className='col-lg-5 col-md-5 moduleitem shadow-sm p-2  mb-1 bg-white rounded'>
//         <div className='row'> */}
//       {/* <div className='col-lg-12 col-md-12'>
//             <h6 className='text-danger'>{props.module.module_id}</h6>
//             <h6 className='text-danger'>{props.module.module}</h6>
//             <div className=''>
//               <span>{props.module.description}</span>
//             </div> */}

//       {/* <hr></hr> */}



//       {/* <button type='button' className='btn btn-primary' data-toggle='modal' data-bs-toggle='modal' data-bs-target={'#exampleModal' + props.module.module_id}>
//             Start Test
//           </button> */}
//       {/* <div className='modal fade' role='dialog' id={'exampleModal' + props.module.module_id} tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' >
//         <div className='modal-dialog  modal-dialog-centered' align="center" role='document'>
//           <div className='modal-content ' align="center">
//             <div className='modal-header' >
//               <h5 className=' hmodule' id='exampleModalLabel' >
//                 {props.module.module} - Select Level
//               </h5>
//             </div>
//             <div className='modal-body '>
//               <Link to={'/question/' + props.module.module_id + '/1'} style={{ color: ' #FFFFFF' }} >
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal'>
//                   Level1
//                 </button>
//               </Link>
//               &nbsp;
//               <Link to={'/question/' + props.module.module_id + '/2'} style={{ color: ' #FFFFFF' }} >
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal' >
//                   Level 2
//                 </button>
//               </Link>
//               &nbsp;
//               <Link to={'/question/' + props.module.module_id + '/3'} style={{ color: ' #FFFFFF' }}>
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal' >
//                   Level 3
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div> */}
//       {/* </div>
//       </div> */}
//       {/* </div > */}


//       < tr >
//         <td>{props.keys}</td>
//         <td>{props.module.module}</td>
//         <td>{props.module.description}</td>
//         <td><button type='button' className='btn btn-primary' data-toggle='modal' data-bs-toggle='modal' data-bs-target={'#exampleModal' + props.module.module_id}>
//           Start Test
//         </button></td>

//       </tr >


//       <div className='modal fade' role='dialog' id={'exampleModal' + props.module.module_id} tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' >
//         <div className='modal-dialog  modal-dialog-centered' align="center" role='document'>
//           <div className='modal-content ' align="center">
//             <div className='modal-header' >
//               <h5 className=' hmodule' id='exampleModalLabel' >
//                 {props.module.module} - Select Level
//               </h5>
//             </div>
//             <div className='modal-body '>
//               <Link to={'/question/' + props.module.module_id + '/1'} style={{ color: ' #FFFFFF' }} >
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal'>
//                   Level1
//                 </button>
//               </Link>
//               &nbsp;
//               <Link to={'/question/' + props.module.module_id + '/2'} style={{ color: ' #FFFFFF' }} >
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal' >
//                   Level 2
//                 </button>
//               </Link>
//               &nbsp;
//               <Link to={'/question/' + props.module.module_id + '/3'} style={{ color: ' #FFFFFF' }}>
//                 <button type='button' className='btn btn-primary' data-bs-dismiss='modal' data-bs-target='#exampleModal' >
//                   Level 3
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>

//     </>
//   )
// }


import React from 'react';
import './ModuleItem.css';
import TestService from '../../services/TestService';
import { useSelector } from 'react-redux';

export default function ModuleItem(props) {

  const user = useSelector(state => state.user.value)
  // const handleStartTest = async (level) => {
  //   const userId = user.userId; // Parse userid to integer
  //   const moduleId = props.module.module_id;
  //   console.log(userId, moduleId)
  //   try {
  //     const response = await TestService.checkTestLimit(userId, moduleId, level);
  //     const result = await response.json();
  //     console.log(response, result)
  //     if (result.status && result.data > 0) {
  //       // alert(result.msg);
  //       window.location.href = `/question/${moduleId}/${level}`;
  //     } else if (result.status && result.data === 0) {
  //       alert(result.msg);
  //     } else {
  //       console.log(result)
  //       alert('An error occurred while checking the test limit.');
  //     }
  //   } catch (error) {
  //     alert('An error occurred while checking the test limit.');
  //     console.error('Error:', error);
  //   }
  // };

  const handleStartTest = async (level) => {
    const userId = user.userId; // Parse userid to integer
    const moduleId = props.module.module_id;

    try {
      const response = await TestService.checkTestLimit(userId, moduleId, level);
      const result = await response.json();
      if (result.status && result.data > 0) {
        console.log("1st if", result.status, result.data)
        window.location.href = `/question/${moduleId}/${level}`;
      } else if (!result.status && result.msg === 'No test records found for the user.') {
        console.log("2nd if", result.status, result.data, result.msg)
        window.location.href = `/question/${moduleId}/${level}`;
      } else {
        if (result.status && result.data > 0 && result.data == 0) {
          console.log("3rd if", result.status, result.data, result.msg)
          window.location.href = `/question/${moduleId}/${level}`;
        } else if (result.status && result.data === 0) {
          console.log("4th if", result.status, result.data, result.msg)
          alert(result.msg); // Show message that max test limits reached
        }
      } if (result.status == false && result.msg == "User not found") {
        console.log("2nd if", result.status, result.data, result.msg)
        window.location.href = `/question/${moduleId}/${level}`;
      }
    } catch (error) {
      alert('An error occurred while checking the test limit.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <tr>
        <td>{props.keys}</td>
        <td>{props.module.module}</td>
        <td>{props.module.description}</td>
        <td>
          <button
            type='button'
            className='btn btn-green'
            data-toggle='modal'
            data-bs-toggle='modal'
            data-bs-target={`#exampleModal${props.module.module_id}`}
          >
            Start Test
          </button>
        </td>
      </tr>

      <div
        className='modal fade'
        role='dialog'
        id={`exampleModal${props.module.module_id}`}
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' align="center" role='document'>
          <div className='modal-content' align="center">
            <div className='modal-header'>
              <h5 className='hmodule' id='exampleModalLabel'>
                {props.module.module} - Select Level
              </h5>
            </div>
            <div className='modal-body'>
              <button type='button' className='btn btn-green' onClick={() => handleStartTest(1)}>
                Level 1
              </button>
              &nbsp;
              <button type='button' className='btn btn-green' onClick={() => handleStartTest(2)}>
                Level 2
              </button>
              &nbsp;
              <button type='button' className='btn btn-green' onClick={() => handleStartTest(3)}>
                Level 3
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
