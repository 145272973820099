class TestService {
  saveTestByUserId(userid, moduleId, quesLevel, date, answer, totalQ) {
    let params = {
      test: {
        userid: userid,
        moduleId: moduleId,
        quesLevel: quesLevel,
        date: date,
        totalQ: totalQ
      },
      answer: answer
    }
    // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ', params)
    return fetch('https://backend.skilledfresher.in/api/test/savetest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Authentication': 'Bearer ' ,token
      },
      body: JSON.stringify(params)
    })
  }

  getTestAnswer(userid) {
    // console.log('testserivce', userid)
    return fetch('https://backend.skilledfresher.in/api/test/listById', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userid })
    })
  }

  // getTotalQuesByTestId (testid) {
  //   return fetch('http://localhost:8989/api/test/ques' + '/' + testid)
  // }

  getTestByTestId(testid) {
    return fetch('https://backend.skilledfresher.in/api/test' + '/' + testid)
  }


  getModuleByTestId(testid) {
    // console.log('>>>>>>>>>>>>>>>>>> ', testid)
    return fetch('https://backend.skilledfresher.in/api/test/module' + '/' + testid)
  }

  getQuesCountByTestId(testid) {
    // console.log('>>>>>>>>>>>>>>>>>> ', testid)
    return fetch('https://backend.skilledfresher.in/api/test/attend' + '/' + testid)
  }

  // userid,testid,ques_id,answer,isright
  // saveTestAnswer (userid, testid, ques_id, answers, isright) {
  //   console.log('>>>>>>>>>>>>>>>>>> ', userid)
  //   console.log('>>>>>>>>>>>>>>>>>> ', testid)
  //   console.log('>>>>>>>>>>>>>>>>>> ', ques_id)
  //   console.log('>>>>>>>>>>>>>>>>>> ', answers)
  //   console.log('>>>>>>>>>>>>>>>>>> ', isright)

  //   let params = {
  //     userid: userid,
  //     testid: parseInt(testid),
  //     ques_id: ques_id,
  //     answer: answers,
  //     isright: isright
  //   }
  //   console.log('>>>>>>>>>>>>>>>>>> ', params)
  //   return fetch('http://localhost:8989/api/test/saveanswer', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //       // 'Authentication': 'Bearer ' ,token
  //     },

  //     body: JSON.stringify(params)
  //   }).then(res => {
  //     console.log(res)
  //   })
  // }
  checkTestLimit(userid, moduleId, quesLevel) {
    console.log(userid, moduleId, quesLevel)
    const url = `https://backend.skilledfresher.in/api/test/checkTestLimit?userid=${userid}&moduleId=${moduleId}&quesLevel=${quesLevel}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


}

export default new TestService()
