import React, { useState, useEffect } from 'react';
import TestService from '../../services/TestService';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

export default function ViewUserTest(props) {
    const [testByUser, setTestByUser] = useState([]);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');


    const { userid } = useParams()
    // console.log(userid)
    useEffect(() => {


        TestService.getTestAnswer(userid)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setTestByUser(data.testHistory.test);
            });

    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const renderSortArrow = (column) => {
        if (column === sortColumn) {
            return sortDirection === 'asc' ? <span>&#x25B2;</span> : <span>&#x25BC;</span>;
        }
        return <span>&#x2195;</span>; // Double arrow icon indicating sortable column
    };

    const sortedTestByUser = testByUser.sort((a, b) => {
        if (sortColumn === 'testid') {
            return sortDirection === 'asc'
                ? a.testid - b.testid
                : b.testid - a.testid;
        } else if (sortColumn === 'date') {
            return sortDirection === 'asc'
                ? new Date(a.date) - new Date(b.date)
                : new Date(b.date) - new Date(a.date);
        } else if (sortColumn === 'module_title') {
            return sortDirection === 'asc'
                ? a.module_title.localeCompare(b.module_title)
                : b.module_title.localeCompare(a.module_title);
        } else if (sortColumn === 'quesLevel') {
            return sortDirection === 'asc'
                ? parseInt(a.quesLevel) - parseInt(b.quesLevel)
                : parseInt(b.quesLevel) - parseInt(a.quesLevel);
        }
        return 0;
    });

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <h3 className="text-center mb-5 mt-5">Test-History!</h3>

                <div className="container">
                    <div className="row">
                        <div className="table-responsive">
                            {testByUser.length > 0 ? (
                                <table className="table col-lg-12 col-sm-12 col-md-12">
                                    <thead>
                                        <tr className="text-center">
                                            <th>S.no</th>
                                            <th onClick={() => handleSort('testid')}>
                                                #Test {renderSortArrow('testid')}
                                            </th>
                                            <th onClick={() => handleSort('date')}>
                                                Date {renderSortArrow('date')}
                                            </th>
                                            <th onClick={() => handleSort('module_title')}>
                                                Module {renderSortArrow('module_title')}
                                            </th>
                                            <th onClick={() => handleSort('quesLevel')}>
                                                Level {renderSortArrow('quesLevel')}
                                            </th>
                                            <th>Total question</th>
                                            <th>Attempted</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedTestByUser.map((ob, index) => (
                                            <tr key={index} className="text-center">
                                                <td>{index + 1}</td>
                                                <td>{ob.testid}</td>
                                                <td>{formatDate(ob.date)}</td>
                                                <td>{ob.module_title}</td>
                                                <td>{ob.quesLevel}</td>
                                                <td>{ob.totalQ}</td>
                                                <td>{ob.totalQues.length}</td>
                                                <td>
                                                    <Link to={'/History/' + ob.testid}>
                                                        <button
                                                            className="btn btn-secondary"
                                                            style={{
                                                                backgroundColor: '',
                                                                height: 'auto',
                                                                width: '60px',
                                                                borderRadius: '10px'
                                                            }}
                                                        >
                                                            {ob.totalQues.reduce(
                                                                (x, ob) => (ob.isright === 1 ? x + 1 : x),
                                                                0
                                                            )}/{ob.totalQ}
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            ) : (
                                <div className="text-center">
                                    <h3>No records found !</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}





