import React from 'react';

import CourseItem from './CourseItem';
import CoursesService from '../../services/CoursesService';
import { Navigate } from 'react-router-dom';
import './CourseItem.css'
export default class Courses extends React.Component {
  constructor() {
    super();
    this.state = {
      courses: [],
      isLogin: false,
    };
  }
  componentDidMount() {
    let token;
    CoursesService.getAllCourses()
      .then(response => response.json())
      .then(data => {
        // console.log(data);

        this.setState({ courses: data.course.course, isLogin: true });
      })
      .catch(err => {
        // console.log(err);
      });
  }

  render() {
    // if (!this.state.isLogin) return <Navigate to="/login" />;

    return (
      <div>


        <div class="container">
          <center>
            <h3 className=" mt-5 mb-5 " >Select Skill</h3></center>
          <div className="row" >


            {this.state.courses.map((cour, index) => (
              <CourseItem key={index} course={cour} />
            ))}

          </div>
        </div>
      </div>
    );
  }
}
