
import React from 'react';
import ModulesService from '../../services/ModulesService';
import withRouter from '../withRouter';
import ModuleItem from './ModuleItem';
import './ModuleItem.css';

class Module extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modules: []
        };
    }

    componentDidMount() {
        const id = this.props.params.id;

        ModulesService.getCourseModuleByCourseId(id)
            .then(response => response.json())
            .then(data => {
                this.setState({ modules: data.modules.course_modules });
            });
    }

    render() {
        const userid = this.props.params.id;
        console.log(userid)

        return (
            <div>
                <br></br>
                <br></br>
                <br></br>
                <div className="container">
                    <center><h3 className="mt-2 mb-3">Select Module</h3></center>
                    <br></br>

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Module</th>
                                    <th>Description</th>
                                    <th>Start Test</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.modules.map((mod, index) => (
                                    <ModuleItem key={index} module={mod} keys={index + 1} userid={userid} history={this.props.history} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Module);






