class ModulesService {
  getCourseModuleByCourseId(id) {
    return fetch('https://backend.skilledfresher.in/api/courseModule/listBymodule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    })
  }

  getModuleById(module_id) {
    // console.log('>>>>>>>>>>>>>>>>>> ', module_id)

    return fetch(
      'https://backend.skilledfresher.in/api/module/getModuleById' + '/' + module_id
    )
  }

  getAllModules() {
    // console.log()
    return fetch('https://backend.skilledfresher.in/api/module/list')
  }
}
export default new ModulesService()
