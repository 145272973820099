import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import RegisterService from "../../services/RegisterService";

export default function EmailVerify() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const vcode = searchParams.get("code");
    const userId = searchParams.get("userId");
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState(null);

    useEffect(() => {
        verifyEmail();
    }, []);

    const verifyEmail = () => {
        RegisterService.verifyEmail(userId, vcode)
            .then((response) => {
                if (response.status === 200) {
                    setVerificationStatus("success");
                } else {
                    setVerificationStatus("fail");
                }
            })
            .catch((error) => {
                setVerificationStatus("fail");
            });
    };

    const handleLogin = () => {
        navigate("/login"); // Replace "/login" with the actual path to your login page
    };

    return (
        <div className="text-center mt-5">
            {verificationStatus === "success" ? (
                <>
                    <h3>Your Email is Verified Successfully</h3>
                    <h4>
                        Please <u onClick={handleLogin}>Login here</u> to continue.
                    </h4>
                </>
            ) : verificationStatus === "fail" ? (
                <h3>Verification Failed</h3>
            ) : (
                <h3>Verifying Email...</h3>
            )}
        </div>
    );
}
