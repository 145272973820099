class RegisterService {
    RegisterUser(data) {

        // console.log(data)
        return fetch("https://backend.skilledfresher.in/api/register",

            {
                method: "POST",
                headers:
                {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        )
    }

    RegisterUsertwo(data) {

        // console.log(data)
        return fetch("https://resume-builder.skilledfresher.in/register_api/",

            {
                method: "POST",
                headers:
                {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        )
    }
    getuserbyemail(email) {
        // console.log('>>>>>>>>>>>>>>>>>> ', email)

        return fetch(
            'https://backend.skilledfresher.in/api/user' + '/' + email
        )
    }

    getuserbycontact(contact) {
        // console.log('>>>>>>>>>>>>>>>>>> ', contact)

        return fetch(
            'https://backend.skilledfresher.in/api/userbycontact' + '/' + contact
        )
    }

    verifyEmail(userId, vcode) {
        return fetch('https://backend.skilledfresher.in/api/verifyEmail' + '/' + userId, {
            method: 'POST',
            body: JSON.stringify({ vcode }), // Wrap vcode in an object
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    forgotPassword(email) {
        console.log(email)
        return fetch('https://backend.skilledfresher.in/api/forgotPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }), // Wrap vcode in an object
        });

    }

    updatePassword(userId, passwordData) {
        console.log(userId, passwordData)
        return fetch(`https://backend.skilledfresher.in/api/changePassword/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(passwordData),
        });
    }

    profile(userId) {
        console.log(userId);
        return fetch(`https://backend.skilledfresher.in/api/profile/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => response.json());
    }


    updateProfile(userId, profileData) {

        return fetch(`https://backend.skilledfresher.in/api/users/update/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profileData),
        }).then(response => response.json());




    }

}

export default new RegisterService()