// import React, { Component } from "react";
// import TestService from "../services/TestService";
// import "./Success.css";
// // import withRouter from "./withRouter";
// import { Link } from 'react-router-dom'
// import { changeTestData } from "../reduxdata/TestSlice";

// import { useSelector, useDispatch } from "react-redux";
// export default function Success() {

//   const test = useSelector(state => state.test.value)
//   var dispatch = useDispatch()

//   return <>
//     <div className="msg">
//       <h2 className="mt-5">Thank You!</h2>
//       <h3 className="text-center">Your Submission has been recieved !..</h3>
//       <p>You can check Your test results at <Link to='/testhistory'>TestHistory</Link>... </p>
//     </div>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>

//   </>


// }


import React, { useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import "./Success.css";
import { changeTestData } from "../reduxdata/TestSlice";

export default function Success() {
  const test = useSelector(state => state.test.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Override the back button behavior
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate('/course'); // Redirect to the course page
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return (
    <div className="msg">
      <h2 className="mt-5">Thank You!</h2>
      <h3 className="text-center">Your Submission has been received!</h3>
      <p>You can check your test results at <Link to='/testhistory'>TestHistory</Link>...</p>
    </div>
  );
}

