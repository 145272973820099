// // import React from "react"
// // import { useEffect, useState } from "react"
// // import AdminService from "../../services/AdminService";
// // import withRouter from "../withRouter";

// // function ViewUser({ props }) {

// //     const [user, setUser] = useState([])
// //     useEffect(() => {

// //         AdminService.getAllUser()
// //             .then((response) => response.json())
// //             .then((data) => {
// //                 // console.log(data.course);
// //                 setUser(data.user.user);
// //             });



// //     }, [])






// //     return <>
// //         <br></br>
// //         <br></br>

// //         <div className='container'>
// //             <center><h2>Users List</h2></center><br></br>
// //             <div className="form-group row">
// //                 {/* <label className="col-sm-2 col-form-label">Search here..</label> */}
// //                 <div className="col-sm-3 col-lg-3 col-md-3">
// //                     <input type="text" className="form-control" id="" placeholder="Search..."

// //                     />

// //                 </div> <h2 className="sr-only">search data....</h2>
// //                 <button className="btn btn-sm btn-primary" style={{ borderRadius: "10px" }}>Search
// //                 </button>
// //             </div>
// //             <table className="table table-bordered  ">
// //                 <thead>
// //                     <tr className="text-center">
// //                         <th>S.no</th>
// //                         <th>UserId</th>
// //                         <th>Name</th>
// //                         <th>Education</th>
// //                         <th>Contact</th>
// //                         <th>Email</th>
// //                         <th>Role</th>

// //                         <th>Action</th>
// //                     </tr>
// //                 </thead>
// //                 <tbody>
// //                     {/* {user.map((ob, index) => <tr key={index}> */}
// //                     {user.filter((r) => r.role == 'student').map((ob, index) => <tr key={index}>

// //                         <td>{index + 1}</td>
// //                         <td>{ob.userid}</td>
// //                         <td>{ob.name}</td>
// //                         <td>{ob.education}</td>
// //                         <td>{ob.contact}</td>
// //                         <td>{ob.email}</td>
// //                         <td>{ob.role}</td>


// //                         <td><button className="btn btn-secondary" style={{ borderRadius: "10px" }}>Block
// //                         </button>&nbsp;


// //                             <button className="btn btn-danger" style={{ borderRadius: "10px" }}>Delete
// //                             </button></td>
// //                     </tr>)}
// //                 </tbody>

// //             </table>

// //         </div>


// //     </>
// // }
// // // export default withRouter(ViewQuestion)
// // export default withRouter(ViewUser)



// import React, { useEffect, useState } from "react";
// import AdminService from "../../services/AdminService";
// import withRouter from "../withRouter";
// import TestService from "../../services/TestService";
// import { useNavigate } from "react-router-dom";

// function ViewUser({ props }) {
//     const [user, setUser] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [searchValue, setSearchValue] = useState("");
//     const itemsPerPage = 10;
//     const [isDataUpdated, setIsDataUpdated] = useState(false);
//     const [blockedUsers, setBlockedUsers] = useState([]);
//     const [unblockedUser, setunBlockedUser] = useState([]);
//     const navigate = useNavigate()

//     const [testByUser, setTestByUser] = useState([]);

//     const delUser = (userid) => {
//         const confirmDelete = window.confirm("Are you sure you want to delete this user ? ");
//         if (confirmDelete) {
//             AdminService.dleteUser(userid).then((response) => response.json()).then((data) => {
//                 // console.log(data)
//                 setUser((u) => u.filter(usr => usr.userid !== userid))
//                 setIsDataUpdated(!isDataUpdated)
//             })
//         }
//     }

//     const result = (userid) => {

//         navigate("/usertest/" + userid)
//     }

//     const blockUser = (userid) => {
//         const confirmBlock = window.confirm("Are you sure you want to block this user?");
//         if (confirmBlock) {
//             const updatedRole = { role: "blocked" }; // Create an object with the updated role
//             AdminService.updateUserRole(userid, updatedRole)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     // console.log(data);
//                     const blockedUser = user.find((user) => user.userid === userid);
//                     setUser((users) => [...users, unblockedUser]);
//                     setBlockedUsers((blockedUsers) => [...blockedUsers, blockedUser]);
//                     setIsDataUpdated(!isDataUpdated);
//                 });
//         }
//     };


//     const unblockUser = (userid) => {
//         const confirmUnblock = window.confirm("Are you sure you want to unblock this user?");
//         if (confirmUnblock) {
//             const updatedRole = { role: "student" }; // Create an object with the updated role
//             AdminService.updateUserRole(userid, updatedRole)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     // console.log(data);
//                     const updatedUser = user.find((user) => user.userid === userid);
//                     updatedUser.role = "student";
//                     setBlockedUsers((blockedUsers) => blockedUsers.filter((user) => user.userid !== userid));
//                     setUser((users) => [...users]);
//                     setIsDataUpdated(!isDataUpdated);
//                 });
//         }
//     };

//     useEffect(() => {
//         AdminService.getAllUser()
//             .then((response) => response.json())
//             .then((data) => {
//                 setUser(data.user.user);
//             });


//     }, [isDataUpdated]);

//     const handleNextPage = () => {
//         setCurrentPage((prevPage) => prevPage + 1);
//     };

//     const handlePreviousPage = () => {
//         setCurrentPage((prevPage) => prevPage - 1);
//     };

//     const handleSearchChange = (event) => {
//         setSearchValue(event.target.value);
//         setCurrentPage(1);
//     };

//     const handlePageClick = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     const getPageNumbers = () => {
//         const pageNumbers = [];
//         const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
//         for (let i = 1; i <= totalPages; i++) {
//             pageNumbers.push(i);
//         }
//         return pageNumbers;
//     };
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;

//     // Filtered users based on search value
//     const filteredUsers = user.filter(
//         (r) =>
//             (r.role === "student" || r.role === "blocked") &&
//             (r.name.toLowerCase().includes(searchValue.toLowerCase()) ||
//                 r.email.toLowerCase().includes(searchValue.toLowerCase()))
//     );

//     // Users to display on the current page
//     const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

//     return (
//         <>
//             <br />
//             <br />
//             <div className="container">
//                 <center>
//                     <h2>Users List</h2>
//                 </center>
//                 <br />
//                 <div className="form-group row">
//                     <div className="col-sm-3 col-lg-3 col-md-3">
//                         <input
//                             type="text"
//                             className="form-control"
//                             id=""
//                             placeholder="Search by Name and Email"
//                             value={searchValue}
//                             onChange={handleSearchChange}
//                         />
//                     </div>
//                     <h2 className="sr-only">search data....</h2>
//                     <button
//                         className="btn btn-sm btn-primary"
//                         style={{ borderRadius: "10px" }}
//                     >
//                         Search
//                     </button>
//                 </div>
//                 <div className="table-responsive">

//                     <table className="table table-bordered ">
//                         <thead>
//                             <tr className="text-center">
//                                 <th>S.no</th>
//                                 <th>UserId</th>
//                                 <th>Name</th>
//                                 <th>Education</th>
//                                 <th>Contact</th>
//                                 <th>Email</th>
//                                 <th>Role</th>
//                                 <th>Test</th>

//                                 <th>Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentItems
//                                 .filter((o) => o.role !== "blocked").map((ob, index) => (
//                                     <tr key={index} >
//                                         <td>{indexOfFirstItem + index + 1}</td>
//                                         <td>{ob.userid}</td>
//                                         <td>{ob.name}</td>
//                                         <td>{ob.education}</td>
//                                         <td>{ob.contact}</td>
//                                         <td>{ob.email}</td>
//                                         <td>{ob.role}</td>
//                                         <td><button
//                                             className="btn btn-center btn-sm btn-primary"
//                                             style={{ borderRadius: "10px" }} onClick={() => result(ob.userid)}
//                                         >
//                                             Test History
//                                         </button></td>
//                                         <td>
//                                             <div className="d-flex">
//                                                 <button
//                                                     className="btn btn-sm btn-secondary"
//                                                     style={{ borderRadius: "10px" }} onClick={() => blockUser(ob.userid)}
//                                                 >
//                                                     Block
//                                                 </button>
//                                                 &nbsp;
//                                                 <button
//                                                     className="btn btn-sm btn-danger"
//                                                     style={{ borderRadius: "10px" }} onClick={() => delUser(ob.userid)}
//                                                 >
//                                                     Delete
//                                                 </button>
//                                             </div>
//                                             {/* <button
//                                                 className="btn btn-center btn-sm btn-primary"
//                                                 style={{ borderRadius: "10px" }} onClick={() => result(ob.userid)}
//                                             >
//                                                 Test History
//                                             </button> */}
//                                         </td>
//                                     </tr>
//                                 ))}
//                         </tbody>
//                     </table>

//                 </div>
//                 <h1 className="text-center">Blocked Users</h1>
//                 <div className="table-responsive">

//                     <table className="table table-bordered">

//                         <thead>
//                             <tr className="text-center">
//                                 <th>S.no</th>
//                                 <th>UserId</th>
//                                 <th>Name</th>
//                                 <th>Education</th>
//                                 <th>Contact</th>
//                                 <th>Email</th>
//                                 <th>Role</th>
//                                 <th>Action</th>
//                             </tr>
//                         </thead>

//                         <tbody>
//                             {currentItems.filter(o => o.role == 'blocked').map((ob, index) => (
//                                 <tr key={index}>
//                                     <td>{indexOfFirstItem + index + 1}</td>
//                                     <td>{ob.userid}</td>
//                                     <td>{ob.name}</td>
//                                     <td>{ob.education}</td>
//                                     <td>{ob.contact}</td>
//                                     <td>{ob.email}</td>
//                                     <td>{ob.role}</td>
//                                     <td>
//                                         {ob.role === "student" && (
//                                             <button
//                                                 className="btn btn-secondary"
//                                                 style={{ borderRadius: "10px" }}
//                                                 onClick={() => blockUser(ob.userid)}
//                                             >
//                                                 Block
//                                             </button>
//                                         )}
//                                         {ob.role === "blocked" && (
//                                             <button
//                                                 className="btn btn-success"
//                                                 style={{ borderRadius: "10px" }}
//                                                 onClick={() => unblockUser(ob.userid)}
//                                             >
//                                                 Unblock
//                                             </button>
//                                         )}
//                                         <button
//                                             className="btn btn-danger"
//                                             style={{ borderRadius: "10px" }}
//                                             onClick={() => delUser(ob.userid)}
//                                         >
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>

//                     </table>
//                 </div>
//                 <div className="container d-flex justify-content-between pagination-container">
//                     {/* <div className="container d-flex justify-content-between"> */}
//                     <button
//                         type="button"
//                         className="btn btn-dark"
//                         onClick={handlePreviousPage}
//                         disabled={currentPage === 1}
//                     >
//                         &larr; Previous
//                     </button>
//                     <div>
//                         {getPageNumbers().map((pageNumber) => (
//                             <button
//                                 key={pageNumber}
//                                 type="button"
//                                 className={`btn btn-dark mr-1 ${currentPage === pageNumber ? 'active' : ''}`}
//                                 onClick={() => handlePageClick(pageNumber)}
//                             >
//                                 {pageNumber}
//                             </button>
//                         ))}
//                     </div>
//                     <button
//                         type="button"
//                         className="btn btn-dark"
//                         onClick={handleNextPage}
//                         disabled={indexOfLastItem >= filteredUsers.length}
//                     >
//                         Next &rarr;
//                     </button>
//                     {/* </div> */}
//                 </div>
//             </div>
//         </>
//     );
// }

// export default withRouter(ViewUser);


import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { useNavigate } from "react-router-dom";

function ViewUser({ props }) {
    const [user, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const itemsPerPage = 10;
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const navigate = useNavigate();

    // const delUser = (userid) => {
    //     console.log(userid)
    //     const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    //     if (confirmDelete) {
    //         setIsLoading(true);
    //         AdminService.deleteUser(userid).then((response) => response.json()).then((data) => {
    //             setUser((u) => u.filter(usr => usr.userid !== userid));
    //             setIsDataUpdated(!isDataUpdated);
    //             setIsLoading(false);
    //         });
    //     }
    // };


    const delUser = (userid) => {
        console.log(userid)
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            setIsLoading(true);
            AdminService.dleteUser(userid)
                .then((response) => {
                    // Check if the deletion was successful
                    if (response.ok) {
                        // Remove the deleted user from state
                        setUser((prevUsers) => prevUsers.filter(user => user.userid !== userid));
                        setIsDataUpdated(!isDataUpdated); // Trigger re-fetch if necessary
                    } else {
                        // Handle error or display message if deletion fails
                        console.error("Failed to delete user.");
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Error deleting user:", error);
                    setIsLoading(false);
                });
        }
    };


    const result = (userid) => {
        navigate("/usertest/" + userid);
    };

    const blockUser = (userid) => {
        const confirmBlock = window.confirm("Are you sure you want to block this user?");
        if (confirmBlock) {
            setIsLoading(true);
            const updatedRole = { role: "blocked" };
            AdminService.updateUserRole(userid, updatedRole)
                .then((response) => response.json())
                .then((data) => {
                    const blockedUser = user.find((user) => user.userid === userid);
                    blockedUser.role = "blocked";
                    setIsDataUpdated(!isDataUpdated);
                    setIsLoading(false);
                });
        }
    };

    const unblockUser = (userid) => {
        const confirmUnblock = window.confirm("Are you sure you want to unblock this user?");
        if (confirmUnblock) {
            setIsLoading(true);
            const updatedRole = { role: "student" };
            AdminService.updateUserRole(userid, updatedRole)
                .then((response) => response.json())
                .then((data) => {
                    const updatedUser = user.find((user) => user.userid === userid);
                    updatedUser.role = "student";
                    setIsDataUpdated(!isDataUpdated);
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        AdminService.getAllUser()
            .then((response) => response.json())
            .then((data) => {
                if (data && data.user && data.user.user) {
                    const sortedUsers = data.user.user.sort((a, b) => b.userid - a.userid);
                    setUser(sortedUsers);
                } else {
                    setUser([]); // Fallback to empty array if data structure is unexpected
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
                setUser([]); // Fallback to empty array on error
                setIsLoading(false);
            });
    }, [isDataUpdated]);

    const handleSearch = () => {
        setIsLoading(true);
        AdminService.searchUser(searchValue)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data && data.user && data.user.user) {
                    setUser(data.user.user);
                } else {
                    setUser([]);
                }
                setCurrentPage(1);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error searching users:", error);
                setUser([]);
                setIsLoading(false);
            });
    };

    const handleCancelSearch = () => {
        setSearchValue("");
        setIsLoading(true);
        AdminService.getAllUser()
            .then((response) => response.json())
            .then((data) => {
                if (data && data.user && data.user.user) {
                    setUser(data.user.user);
                } else {
                    setUser([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
                setUser([]);
                setIsLoading(false);
            });
    };


    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredUsers = user.filter(
        (r) =>
            (r.role === "student" || r.role === "blocked")
    );

    const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <br />
            <br />
            <div className="container">
                <center>
                    <h3>Users List</h3>
                </center>
                <br />
                {/* <div className="form-group row">
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name and Email"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <button className="btn btn-primary" onClick={handleSearch}>Search</button>
                    </div>
                </div> */}<div className="form-group row">
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name and Email"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <button className="btn btn-green" onClick={handleSearch}>Search</button>
                        {searchValue && (
                            <button className="btn btn-secondary ml-2" onClick={handleCancelSearch}>Cancel</button>
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <div className="text-center">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> Loading...</span>
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr className="text-center">
                                        <th>S.no</th>
                                        <th>UserId</th>
                                        <th>Name</th>
                                        <th>Education</th>
                                        <th>Contact</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Test</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems
                                        .filter((o) => o.role !== "blocked")
                                        .map((ob, index) => (
                                            <tr key={index}>
                                                <td>{indexOfFirstItem + index + 1}</td>
                                                <td>{ob.userid}</td>
                                                <td>{ob.name}</td>
                                                <td>{ob.education}</td>
                                                <td>{ob.contact}</td>
                                                <td>{ob.email}</td>
                                                <td>{ob.role}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-center btn-sm btn-green"
                                                        style={{ borderRadius: "10px" }}
                                                        onClick={() => result(ob.userid)}
                                                    >
                                                        Test History
                                                    </button>
                                                </td>
                                                <td>
                                                    <div className="d-flex">
                                                        <button
                                                            className="btn btn-sm btn-secondary"
                                                            style={{ borderRadius: "10px" }}
                                                            onClick={() => blockUser(ob.userid)}
                                                        >
                                                            Block
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            className="btn btn-sm btn-danger"
                                                            style={{ borderRadius: "10px" }}
                                                            onClick={() => delUser(ob.userid)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <h1 className="text-center">Blocked Users</h1>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr className="text-center">
                                        <th>S.no</th>
                                        <th>UserId</th>
                                        <th>Name</th>
                                        <th>Education</th>
                                        <th>Contact</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.filter((o) => o.role === 'blocked').map((ob, index) => (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td>{ob.userid}</td>
                                            <td>{ob.name}</td>
                                            <td>{ob.education}</td>
                                            <td>{ob.contact}</td>
                                            <td>{ob.email}</td>
                                            <td>{ob.role}</td>
                                            <td>
                                                {ob.role === "blocked" && (
                                                    <button
                                                        className="btn btn-success"
                                                        style={{ borderRadius: "10px" }}
                                                        onClick={() => unblockUser(ob.userid)}
                                                    >
                                                        Unblock
                                                    </button>
                                                )}
                                                <button
                                                    className="btn btn-danger"
                                                    style={{ borderRadius: "10px" }}
                                                    onClick={() => delUser(ob.userid)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="container d-flex justify-content-between pagination-container">
                            <button
                                type="button"
                                className="btn btn-dark"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                &larr; Previous
                            </button>
                            <div>
                                {getPageNumbers().map((pageNumber) => (
                                    <button
                                        key={pageNumber}
                                        type="button"
                                        className={`btn btn-dark mr-1 ${currentPage === pageNumber ? 'active' : ''}`}
                                        onClick={() => handlePageClick(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                ))}
                            </div>
                            <button
                                type="button"
                                className="btn btn-dark"
                                onClick={handleNextPage}
                                disabled={indexOfLastItem >= filteredUsers.length}
                            >
                                Next &rarr;
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default withRouter(ViewUser);
