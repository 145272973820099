class CoursesService {
  getAllCourses() {
    // console.log ('tokennnnnnn', token);
    return fetch('https://backend.skilledfresher.in/api/course/list');
  }

  // all courses with their modules
  getAllCoursesList() {
    // console.log ('tokennnnnnn', token);
    return fetch('https://backend.skilledfresher.in/api/course/listCourse');
  }


  getAllCoursesModule() {
    // console.log ('tokennnnnnn', token);
    return fetch('https://backend.skilledfresher.in/api/CourseModule/list');
  }

}

export default new CoursesService();
