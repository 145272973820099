
class LoginService {
  LoginUser(data) {



    // console.log(">>>>>>>>>>>>>>>>>> ", data)
    // const token = req.user

    return fetch("https://backend.skilledfresher.in/api/login", {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',


      },
      body: JSON.stringify(data)



    })



  }


  LoginUserTwo(data) {
    console.log("Sending data to resume login:", data);
    return fetch("https://resume-builder.skilledfresher.in/login_api/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.getCsrfToken(), // Include CSRF token if required
      },
      credentials: 'include', // Ensure cookies are sent with the request
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            console.error('Login failed:', errorData.message);
            throw new Error('Network response was not ok.');
          });
        }
        return response.json();
      })
      .catch(error => {
        console.error('Fetch error:', error);
        throw error;
      });
  }

  getCsrfToken() {
    const cookies = document.cookie.split('; ');
    const csrfToken = cookies.find(row => row.startsWith('csrftoken='));
    return csrfToken ? csrfToken.split('=')[1] : '';
  }
}
export default new LoginService()
