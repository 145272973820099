import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RegisterService from '../../services/RegisterService';
import './Register.css';

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      students: [],
      msg: '',
      name: '',
      education: '',
      contact: '',
      email: '',
      password: '',
      confirm_password: '',
      errors: {},
    };
  }

  validateForm = () => {
    const { name, education, contact, email, password, confirm_password } = this.state;
    const errors = {};
    let isValid = true;

    if (name.trim() === '') {
      errors.name = 'Please enter your name';
      isValid = false;
    }
    if (education.trim() === '') {
      errors.education = 'Please enter your education';
      isValid = false;
    }
    if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(contact)) {
      errors.contact = 'Please enter a valid phone number';
      isValid = false;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.email = 'Please enter a valid email';
      isValid = false;
    }
    if (password.length < 6) {
      errors.password = 'Password should be more than 6 characters';
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.password = 'Password must contain at least one special character';
      isValid = false;
    }
    if (password !== confirm_password) {
      errors.confirm_password = 'Password and confirm password should match';
      isValid = false;
    }

    return errors;
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRegister = event => {
    event.preventDefault();
    const errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      const { name, education, contact, email, password, confirm_password } = this.state;
      const userData1 = {
        name,
        education,
        contact,
        email,
        password,

      };
      const userData2 = {
        email: email,
        first_name: name,
        last_name: name,

        password1: password,
        password2: password,

      };
      console.log(userData1, userData2)
      Promise.all([
        RegisterService.RegisterUser(userData1),
        RegisterService.RegisterUsertwo(userData2)
      ]).then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
        .then(([userData1, userData2]) => {
          if (userData1) {
            this.setState({ msg: userData1.message, errors: {} });
          } else {
            this.setState({ msg: userData2.email, errors: {} });
          }
          // this.setState({ msg: (userData1.message, userData2.email), errors: {} });
          if (userData1.message == "Your registration is successful and an email verification link has been sent to your email.") {
            this.setState({
              name: '',
              education: '',
              contact: '',
              email: '',
              password: '',
              confirmPassword: '',
            });
          }

          this.setState({});
          event.target.reset()

        })
        .catch(err => {
          // this.setState({});
          this.setState({ msg: 'An error occurred', errors: {} });
          console.log('Error:', err);
        });
      event.target.reset()

    }

  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <br />
        <br />
        <div className="container ">
          <div className="row">
            <div className=' col-lg-12 col-md-12'>


              <h3 className="mb-5 text-center">Registration</h3>
              <center>
                <div id="success-message"></div>

                {this.state.msg == "Your registration is successful and an email verification link has been sent to your email." ?
                  <><b style={{ position: 'center' }} className="text-success">
                    {this.state.msg}</b><br></br></> : <><b style={{ position: 'center' }} className="text-danger">
                      {this.state.msg}<br></br></b></>
                }
                <form onSubmit={this.handleRegister} >

                  <div className="row">

                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>Name</b>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="name"
                        placeholder="Your Name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      {errors.name && <b className="text-danger">{errors.name}</b>}
                    </div>
                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>Education</b>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="education"
                        placeholder="Education"
                        value={this.state.education}
                        onChange={this.handleChange}
                      />
                      {errors.education && <b className="text-danger">{errors.education}</b>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>Contact</b>
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="contact"
                        placeholder="Contact"
                        value={this.state.contact}
                        onChange={this.handleChange}
                      />
                      {errors.contact && <b className="text-danger">{errors.contact}</b>}
                    </div>
                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>Email</b>
                      </label>
                      <input
                        type="email"
                        className="form-control mb-2"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {errors.email && <b className="text-danger">{errors.email}</b>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>Password</b>
                      </label>
                      <input
                        type="password"
                        className="form-control mb-2"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      {errors.password && <b className="text-danger">{errors.password}</b>}
                    </div>
                    <div className="col-lg-6 ">
                      <label className="labels mb-1 float-left">
                        <b>ConfirmPassword</b>
                      </label>
                      <input
                        type="password"
                        className="form-control mb-2"
                        name="confirm_password"
                        placeholder="ConfirmPassword"
                        value={this.state.confirm_password}
                        onChange={this.handleChange}
                      />
                      {errors.confirm_password && (
                        <b className="text-danger">{errors.confirm_password}</b>
                      )}
                    </div>
                  </div>

                  <br />
                  <div className="col-lg-6 col-md-2 col-sm-3 col-xs-3 ">
                    <button align="center" type="submit" className="form-control submit-btn">
                      Register
                    </button>
                  </div>
                  <div className="col-lg-6 col-md-2 col-sm-3 col-xs-3 mt-3">
                    <Link className="lnkbtn" to="/login">
                      <button align="center" className="form-control submit-btnn">
                        Login
                      </button>
                    </Link>
                  </div>
                </form>

              </center>
              {/* </div> */}

              {/* </div> */}


            </div>

          </div>
        </div>
      </div>
    );
  }
}

