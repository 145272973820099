class AdminService {
  addCourse(data) {
    // let params = {
    //   course_title: course_title,
    //   description: description,
    //   active_status: active_status,
    // };
    // console.log(course_title, description, active_status);
    // console.log(data);
    return fetch("https://backend.skilledfresher.in/api/course/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  addModule(data) {

    // console.log(data);
    return fetch("https://backend.skilledfresher.in/api/module/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  addCoursesModules(data) {

    // console.log(data);
    return fetch("https://backend.skilledfresher.in/api/courseModule/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  addQuestion(data) {
    // console.log(data);
    return fetch("https://backend.skilledfresher.in/api/question/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  getAllUser() {
    // console.log ('tokennnnnnn', token);
    return fetch('https://backend.skilledfresher.in/api/user/list');
  }



  deleteCourse(course_id) {
    // console.log(course_id)
    return fetch('https://backend.skilledfresher.in/api/course/delete' + '/' + course_id, {
      method: 'DELETE'
    });
  }

  deleteModule(module_id) {
    // console.log(module_id)
    return fetch('https://backend.skilledfresher.in/api/module/delete' + '/' + module_id, {
      method: 'DELETE'
    });
  }

  deletecourseModule(course_module_id) {
    // console.log(course_module_id)
    return fetch('https://backend.skilledfresher.in/api/courseModule/delete' + '/' + course_module_id, {
      method: 'DELETE'
    });
  }
  deleteQues(ques_id) {
    // console.log(ques_id)
    return fetch('https://backend.skilledfresher.in/api/question/delete/' + ques_id, {
      method: 'DELETE'
    });
  }

  dleteUser(userid) {
    return fetch('https://backend.skilledfresher.in/api/delete' + '/' + userid, {
      method: 'DELETE'
    })
  }

  updateCourse(course_id, updatedCourse) {
    return fetch('https://backend.skilledfresher.in/api/course/update' + '/' + course_id, {
      method: 'PUT', body: JSON.stringify(updatedCourse), // Include the updatedCourse in the request body
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  updateModule(module_id, updatedModule) {
    return fetch('https://backend.skilledfresher.in/api/module/update' + '/' + module_id, {
      method: 'PUT', body: JSON.stringify(updatedModule), // Include the updatedCourse in the request body
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  updateCourseModule(course_module_id, updatedCM) {
    return fetch('https://backend.skilledfresher.in/api/coursemodule/update' + '/' + course_module_id, {
      method: 'PUT', body: JSON.stringify(updatedCM), // Include the updatedCourse in the request body
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }


  updateQuestion(ques_id, updatedQuestions) {
    return fetch('https://backend.skilledfresher.in/api/question/update' + '/' + ques_id, {
      method: 'PUT', body: JSON.stringify(updatedQuestions), // Include the updatedCourse in the request body
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }


  updateUserRole(userid, updatedrole) {
    return fetch('https://backend.skilledfresher.in/api/users/block' + '/' + userid, {
      method: 'PUT', body: JSON.stringify(updatedrole), // Include the updatedCourse in the request body
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }


  searchUser(query) {
    return fetch(`https://backend.skilledfresher.in/api/search?q=${query}`);
  }



}

export default new AdminService();
