import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    value: {
      adminname: undefined,
      token: undefined,
      islogin: false,
      adminId: 0,
      
    }
  },
  reducers: {
    changeAdminData: (state, action) => {
      state.value = action.payload
    }
    

  }
})

export const { changeAdminData } = adminSlice.actions
export default adminSlice.reducer
