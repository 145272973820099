// // import { Component } from "react";
// // import TestService from "../../services/TestService";
// // import { useSelector } from "react-redux";
// import './QuestionItem.css'
// export default function QusItem(props) {
//   // const loginUser = useSelector(state=>state.user.value)

//   var handleChange = event => {
//     var opt = event.target.value

//     props.submitAnswer(props.qus.ques_id, opt, props.qus.answer)
//   }

//   var ques = props.qus;
//   var options = [ques.option1, ques.option2, ques.option3, ques.option4].filter(
//     Boolean
//   );
//   return (
//     <>
//       <div>
//         {/* <form> */}
//         {/* <div className=' col-lg-12 col-md-12 col-sm-12 col-12 '> */}
//         <div className='box'>
//           <div className="">
//             {props.index}.&nbsp;{ques.question}
//           </div>
//           &nbsp;<br></br>
//           {options.map((option, index) => (
//             <div key={index}>
//               <input
//                 type='radio'
//                 onChange={handleChange}
//                 name={'ans' + props.index}
//                 value={String.fromCharCode(97 + index)}
//               />
//               &nbsp;{option}
//             </div>
//           ))}





import React from 'react';
import './QuestionItem.css';

export default function QusItem(props) {
  const handleChange = event => {
    const opt = event.target.value;
    props.submitAnswer(props.qus.ques_id, opt, props.qus.answer);
  };

  const ques = props.qus;
  const options = [ques.option1, ques.option2, ques.option3, ques.option4].filter(Boolean);

  return (
    <div className='box'>
      <div>
        <span>{props.index}.&nbsp;</span>
        <span dangerouslySetInnerHTML={{ __html: ques.question }} />
      </div>
      <br />
      {options.map((option, index) => (
        <div key={index}>
          <input
            type='radio'
            onChange={handleChange}
            name={'ans' + props.index}
            value={String.fromCharCode(97 + index)}
          />
          &nbsp;{option}
        </div>
      ))}
    </div>
  );
}

{/* <div>
            <input
              type='radio'
              onChange={handleChange}
              name={'ans' + props.index}
              value='a'
            />
            &nbsp;
            {ques.option1}
          </div>
          <br />
          <div>
            <input
              type='radio'
              onChange={handleChange}
              name={'ans' + props.index}
              value='b'
            />
            &nbsp;
            {ques.option2}
          </div>
          <br />
          <div>
            <input
              type='radio'
              onChange={handleChange}
              name={'ans' + props.index}
              value='c'
            />
            &nbsp;
            {ques.option3}
          </div>
          <br />
          <div>
            <input
              type='radio'
              onChange={handleChange}
              name={'ans' + props.index}
              value='d'
            />
            &nbsp;
            {ques.option4}
          </div> */}
// </div>
{/* </div> */ }
{/* </form> */ }
//       </div >


//     </>
//   )
// }


// // import { Component } from "react";
// // import TestService from "../../services/TestService";
// // import { useSelector } from "react-redux";

// export default function QusItem(props) {
//   // const loginUser = useSelector(state=>state.user.value)

//   var handleChange = event => {
//     var opt = event.target.value

//     props.submitAnswer(props.qus.ques_id, opt, props.qus.answer)
//   }

//   var ques = props.qus
//   return (
//     <>
//       <div>
//         {/* <form> */}
//         {/* <div className=' col-lg-12 col-md-12 col-sm-12 col-12 '> */}
//         <div className='box'>
//           <div className="">
//             {props.index}.&nbsp;{ques.question}
//           </div>
//           &nbsp;
//           <div>
//             <input
//               type='radio'
//               onChange={handleChange}
//               name={'ans' + props.index}
//               value='a'
//             />
//             &nbsp;
//             {ques.option1}
//           </div>
//           <br />
//           <div>
//             <input
//               type='radio'
//               onChange={handleChange}
//               name={'ans' + props.index}
//               value='b'
//             />
//             &nbsp;
//             {ques.option2}
//           </div>
//           <br />
//           <div>
//             <input
//               type='radio'
//               onChange={handleChange}
//               name={'ans' + props.index}
//               value='c'
//             />
//             &nbsp;
//             {ques.option3}
//           </div>
//           <br />
//           <div>
//             <input
//               type='radio'
//               onChange={handleChange}
//               name={'ans' + props.index}
//               value='d'
//             />
//             &nbsp;
//             {ques.option4}
//           </div>
//         </div>
//         {/* </div> */}
//         {/* </form> */}
//       </div >


//     </>
//   )
// }
