

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Menu from './components/Menu';
import Home from './components/Home';
import Contact from './components/Contact';
import Course from './components/course/Course';
import Module from './components/module/Module';
import Question from './components/question/Question';
import Success from './components/Success';
import Register from './components/register/Register';
import Login from './components/Login';
import TestHistory from './components/History/TestHistory';
import History from './components/History/History';
import SaveCourse from './components/admin/SaveCourse';
import SaveModules from './components/admin/SaveModules';
import SaveQuestions from './components/admin/SaveQuestions';
import SaveCourseModule from './components/admin/SaveCourseModule';
import ViewQuestion from './components/admin/ViewQuestion';
import ViewUserDetails from './components/admin/ViewUserDetails';
import EmailVerify from './components/register/EmailVerify';
import ViewUserTest from './components/admin/ViewUserTest';
import ForgotPassword from './components/ForgotPassword';
import UpdatePasword from './components/UpdatePassword';
import Profile from './components/Profile';
import ViewStudentDetails from './components/hr/ViewStudentDetails';
import ViewStudentTest from './components/hr/ViewStudentTest';
import AddFavorite from './components/hr/AddFavorite';
import './App.css'

// Custom PrivateRoute component


const App = () => {
  const isUserAuthenticated = useSelector((state) => state.user.value.islogin);

  return (
    <div>
      <Menu />

      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Login />} />

        <Route path='/course' element={<Course />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/emailverify" element={<EmailVerify />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />



        {isUserAuthenticated && (
          <>
            <Route path="/module/:id" element={<Module />} />
            <Route path="/question/:moduleId/:quesLevel" element={<Question />} />
            <Route path="/success" element={<Success />} />
            <Route path="/testhistory" element={<TestHistory />} />
            <Route path="/usertest/:userid" element={<ViewUserTest />} />

            <Route path="/history/:testid" element={<History />} />
            <Route path="/saveCourse" element={<SaveCourse />} />
            <Route path="/saveModules" element={<SaveModules />} />
            <Route path="/user" element={<ViewUserDetails />} />
            <Route path="/saveQuestions" element={<SaveQuestions />} />
            <Route path="/saveCourseModules" element={<SaveCourseModule />} />
            <Route path="/questionsList" element={<ViewQuestion />} />
            <Route path="/saveQuestions/:ques_id" element={<SaveQuestions />} />
            <Route path="/changePassword" element={<UpdatePasword />} />
            <Route path="/myProfile" element={<Profile />} />

            <Route path="/studentDetail" element={<ViewStudentDetails />} />
            <Route path="/StudentTestRecord/:userid" element={<ViewStudentTest />} />
            <Route path="/addFavorite" element={<AddFavorite />} />





          </>
        )}
      </Routes>
    </div>
  );
};

export default App;


// import React from 'react'

// import Menu from './components/Menu'
// import Home from './components/Home'
// import Contact from './components/Contact'
// import Course from './components/course/Course'
// import Module from './components/module/Module'
// import Question from './components/question/Question'
// import Success from './components/Success'
// import Register from './components/register/Register'
// import Login from './components/Login'
// import TestHistory from './components/History/TestHistory'
// import History from './components/History/History'
// import SaveCourse from './components/admin/SaveCourse'
// import SaveModules from './components/admin/SaveModules'
// import SaveQuestions from './components/admin/SaveQuestions'
// import SaveCourseModule from './components/admin/SaveCourseModule'
// import { Routes, Route } from 'react-router-dom'
// import ViewQuestion from './components/admin/ViewQuestion'
// import ViewUserDetails from './components/admin/ViewUserDetails'
// import EmailVerify from './components/register/EmailVerify'

// export default class App extends React.Component {
//   render() {
//     return (
//       <div>
//         <Menu />

//         <Routes>
//           <Route path='/' element={<Home />} />
//           <Route path='/contact' element={<Contact />} />
//           <Route path='/course' element={<Course />} />
//           <Route path='/module/:id' element={<Module />} />
//           <Route path='/question/:moduleId/:quesLevel' element={<Question />} />
//           <Route path='/success' element={<Success />} />
//           <Route path='/register' element={<Register />} />
//           <Route path='/emailverify' element={<EmailVerify />} />

//           <Route path='/login' element={<Login />} />
//           <Route path='/testhistory' element={<TestHistory />} />
//           <Route path='/History/:testid' element={<History />} />
//           <Route path='/saveCourse' element={<SaveCourse />} />
//           <Route path='/saveModules' element={<SaveModules />} />
//           <Route path='/user' element={<ViewUserDetails />} />

//           <Route path='/saveQuestions' element={<SaveQuestions />} />

//           <Route path='/saveCourseModules' element={<SaveCourseModule />} />
//           <Route path='/questionsList' element={<ViewQuestion />} />
//           <Route path="/SaveQuestions/:ques_id" element={<SaveQuestions />} />




//         </Routes>
//       </div>
//     )
//   }
// }




