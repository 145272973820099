// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import RegisterService from '../services/RegisterService';
// import "./Profile.css";
// import { Link } from 'react-router-dom';

// export default function Profile() {
//     const loginUser = useSelector(state => state.user.value);
//     const [userProfile, setUserProfile] = useState(null);
//     console.log("userProfile", userProfile)
//     useEffect(() => {
//         const fetchProfile = async () => {
//             try {
//                 if (loginUser && loginUser.userId) {
//                     console.log("Fetching profile for userId:", loginUser.userId);
//                     const response = await RegisterService.profile(loginUser.userId);

//                     console.log("Profile API Response:", response);

//                     if (response.status) {
//                         setUserProfile(response.user);
//                     } else {
//                         console.error('Error fetching user profile:', response.message);
//                     }
//                 } else {
//                     console.warn('No valid userId found in loginUser:', loginUser);
//                 }
//             } catch (error) {
//                 console.error('Error fetching user profile:', error);
//             }
//         };

//         fetchProfile();
//     }, [loginUser]);

//     return (
//         <div className="container pp mt-5">
//             <div className="card small-profile-card">
//                 <div className="card-header text-center">
//                     <h4>My Profile <Link to='/editProfile'><i className="uil uil-edit" /></Link></h4>
//                 </div>
//                 <div className="card-body">
//                     {/* <div className='card-text justify-content-end '><i className="uil uil-pen " /></div> */}
//                     {userProfile ? (
//                         <div className="profile-info">
//                             <p><strong>User ID:</strong> {userProfile.userid}</p>
//                             <p><strong>Name:</strong> {userProfile.name}</p>
//                             <p><strong>Education:</strong> {userProfile.education}</p>
//                             <p><strong>Contact:</strong> {userProfile.contact}</p>
//                             <p><strong>Email:</strong> {userProfile.email}</p>
//                             <p><strong>Role:</strong> {userProfile.role}</p>
//                             {/* <p><strong>Email Verified:</strong> {userProfile.email_verified ? 'Yes' : 'No'}</p> */}
//                         </div>
//                     ) : (
//                         <p>Loading...</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }




import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RegisterService from '../services/RegisterService';
import './Profile.css';
import { Link } from 'react-router-dom';

export default function Profile() {
    const loginUser = useSelector(state => state.user.value);
    const [userProfile, setUserProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState('');
    const [education, setEducation] = useState('');
    const [contact, setContact] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                if (loginUser && loginUser.userId) {
                    console.log('Fetching profile for userId:', loginUser.userId);
                    const response = await RegisterService.profile(loginUser.userId);

                    console.log('Profile API Response:', response);

                    if (response.status) {
                        setUserProfile(response.user);
                        // Set initial values in the form fields
                        setName(response.user.name);
                        setEducation(response.user.education);
                        setContact(response.user.contact);
                    } else {
                        console.error('Error fetching user profile:', response.message);
                    }
                } else {
                    console.warn('No valid userId found in loginUser:', loginUser);
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchProfile();
    }, [loginUser]);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleSaveChanges = async () => {
        try {
            const profileData = {
                name: name,
                education: education,
                contact: contact,
                email: userProfile.email
            };
            console.log('profileData', profileData);

            const response = await RegisterService.updateProfile(userProfile.userid, profileData);

            if (response.status) {
                console.log('Profile updated successfully:', response);
                alert(response.message)
                setMsg(response.message);
                // Update the local state with the new profile data
                setUserProfile(prevProfile => ({
                    ...prevProfile,
                    name: profileData.name,
                    education: profileData.education,
                    contact: profileData.contact
                }));
                setShowModal(false); // Close the modal after successful update
            } else {
                setMsg(response.message);
                setShowModal(true);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <div className="container pp mt-5">
            <div className="card small-profile-card">
                <div className="card-header text-center">
                    <h4>
                        My Profile{' '}
                        <Link to="#" className="text-secondary" onClick={handleModalOpen}>
                            <i className="uil uil-edit" />
                        </Link>
                    </h4>
                </div>
                <div className="card-body">
                    {userProfile ? (
                        <div className="profile-info">
                            <p>
                                <strong>User ID:</strong> {userProfile.userid}
                            </p>
                            <p>
                                <strong>Name:</strong> {userProfile.name}
                            </p>
                            <p>
                                <strong>Education:</strong> {userProfile.education}
                            </p>
                            <p>
                                <strong>Contact:</strong> {userProfile.contact}
                            </p>
                            <p>
                                <strong>Email:</strong> {userProfile.email}
                            </p>
                            {/* <p>
                                <strong>Role:</strong> {userProfile.role}
                            </p> */}
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>

            {/* Modal for editing profile */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Profile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleModalClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="education">Education</label>
                                    <input type="text" className="form-control" id="education" value={education} onChange={(e) => setEducation(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="contact">Contact</label>
                                    <input type="text" className="form-control" id="contact" value={contact} onChange={(e) => setContact(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Close</button>
                            <button type="button" className="btn btn-green" onClick={handleSaveChanges}>Save changes</button>
                        </div>
                        <p> {msg}</p>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}></div>
        </div>
    );
}

