import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeData } from '../reduxdata/UserSlice';
import RegisterService from '../services/RegisterService';
import './Login.css';
import { Link, Navigate } from 'react-router-dom'
export default function ForgotPassword() {
    const loginUser = useSelector(state => state.user.value);
    const [msg, setMsg] = useState('');
    const dispatch = useDispatch();

    let emailbox = undefined;

    const handleForgotPassword = async event => {
        event.preventDefault();
        setMsg('Sending link ...');

        const email = emailbox.value;
        console.log(email)
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setMsg('Please enter a valid email address.');
        } else {
            try {
                const response = await RegisterService.forgotPassword(email);
                console.log(email)
                console.log(response)

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                console.log(response)
                const data = await response.json();
                setMsg(data.message);
                alert(`${data.message}\nPlease check and login to continue...`);
            } catch (err) {
                setMsg(`An error occurred: ${err.message}`);
                alert(`An error occurred: ${err.message}`);
            }
        }
    };

    // if (loginUser.role === 'blocked') {
    //     dispatch(changeData({ islogin: false }));
    // } else if (loginUser.islogin && loginUser.role === 'student') {
    //     return <Navigate to='/course' />;
    // } else if (loginUser.islogin && loginUser.role === 'admin') {
    //     return <Navigate to='/' />;
    // } else {
    return (
        <div>
            <br />
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="">
                            <h3 className='text-center'>Forgot Password?</h3>
                            <form onSubmit={handleForgotPassword}>
                                <div className="">
                                    <div className="col-lg-6 col-12 offset-lg-3 col-md-12 col-sm-12">
                                        <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                                        <label className="labels mb-0"><b>Email</b></label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            ref={c => (emailbox = c)}
                                            name="email"
                                            placeholder="Email"
                                            id="email"
                                        />
                                    </div>
                                    <br />
                                </div>
                                <br />
                                <div className="col-lg-6 offset-lg-3 col-md-8 col-sm-8 text-center">
                                    <button type="submit" className="form-control submit-bt text-center">
                                        Send link to email
                                    </button>
                                    <Link to='/register' className='text-info'><span>Create an account</span>&nbsp;</Link>or&nbsp;<Link to='/login' className='text-info'>Login to continue</Link><br></br>
                                    {msg && <b className={msg.includes('sent') ? 'text-success' : 'text-danger'}>{msg}</b>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
// }


