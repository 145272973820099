import { createSlice } from '@reduxjs/toolkit'

export const courseSlice = createSlice({
    name: 'course',
    initialState: {
        courseList: [], // Changed the initial state structure to include courseList
        selectedCourse: null
    },
    reducers: {
        updateCourse: (state, action) => {
            const { courseId, updatedCourse } = action.payload;
            const updatedList = state.courseList.map((course) =>
                course.course_id === courseId ? updatedCourse : course
            );
            state.courseList = updatedList;
            state.selectedCourse = null;
        },
        // Add other actions if needed
    }
});

export const { updateCourse } = courseSlice.actions;

export default courseSlice.reducer;
