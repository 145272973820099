class HrService {

    addFavorite(data) {
        return fetch("https://backend.skilledfresher.in/api/hr/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    }

    favourtList(hruserid) {
        return fetch(`https://backend.skilledfresher.in/api/hr/list/${hruserid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => response.json());
    }


    dleteFav(id, candidate_id) {
        return fetch('https://backend.skilledfresher.in/api/hr/remove' + '/' + id + '/' + candidate_id, {
            method: 'DELETE'
        })
    }

    searchCandidate(query) {
        return fetch(`https://backend.skilledfresher.in/api/hr/search/candidate?q=${query}`);
    }

    searchFavoriteUser(query) {
        return fetch(`https://backend.skilledfresher.in/api/hr/favorites/search?q=${query}`);
    }
}
export default new HrService();