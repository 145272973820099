import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HrService from "../../services/HrService";

function AddFavorite({ props }) {
    const loginUser = useSelector((state) => state.user.value);
    const [user, setUser] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const itemsPerPage = 10;
    const [showFavoritesTable, setShowFavoritesTable] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const navigate = useNavigate();

    const result = (userid) => {
        navigate("/StudentTestRecord/" + userid);
    };


    const addFavorite = (hruserid, candidate_id) => {
        setIsLoading(true);
        // Optimistically update the UI
        setUser(prevUser =>
            prevUser.map(user =>
                user.userid === candidate_id ? { ...user, favstatus: true } : user
            )
        );

        HrService.addFavorite({ hruserid, candidate_id })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // navigate('/studentDetail')
                    alert(data.message);
                    navigate('/studentDetail')
                    // setFavorites((prevFavorites) => [...prevFavorites, data.favorite]);
                } else {
                    alert("Already added to Favourites.");

                    setUser(prevUser =>
                        prevUser.map(user =>
                            user.userid === candidate_id ? { ...user, favstatus: false } : user
                        )
                    );
                }
            })
            .catch((error) => {
                console.error("Error adding favorite:", error);
                alert("Error adding favorite.");
                // Roll back the optimistic update
                setUser(prevUser =>
                    prevUser.map(user =>
                        user.userid === candidate_id ? { ...user, favstatus: false } : user
                    )
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSearch = () => {
        if (!searchValue) {
            setShowValidationMessage(true);
            return;
        }
        setIsLoading(true);
        HrService.searchCandidate(searchValue)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.user && data.user.user && data.user.user.length > 0) {
                    setUser(data.user.user);
                    setShowFavoritesTable(false); // Hide favorites table on successful search
                } else {
                    setUser([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error searching users:", error);
                setUser([]);
                setIsLoading(false);
            });
    };

    const handleCancelSearch = () => {
        setSearchValue("");
        setUser([]); // Clear user list on cancel
        setShowFavoritesTable(false); // Hide favorites table on cancel
        setIsLoading(false); // Reset loading state
        setShowValidationMessage(false); 
    };


    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        if (event.target.value) {
            setShowValidationMessage(false); // Hide validation message when user starts typing
        }
    };

    return (
        <>
            <br />
            <br />
            <div className="container">
                <center>
                    <h3>Find Candidates</h3>
                </center>
                <br />
                <div className="form-group row">
                    <div className="col-sm-4 col-lg-4 col-md-4"></div>

                    {/* <div className="col-sm-3 col-lg-3 col-md-3 text-right">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Email Or Contact"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                    </div> */}
                    <div className="col-sm-3 col-lg-3 col-md-3 text-right">
                        <input
                            type="text"
                            className={`form-control ${showValidationMessage ? 'is-invalid' : ''}`}
                            placeholder="Search by Email Or Contact"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                        {showValidationMessage && (
                            <div className="invalid-feedback">
                                Please enter a search term.
                            </div>
                        )}
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3">
                        <button className="btn btn-green" onClick={handleSearch}>
                            Search
                        </button>
                        &nbsp;

                        {searchValue && (
                            <button className="btn btn-danger ml-2" onClick={handleCancelSearch}>
                                Cancel
                            </button>
                        )}
                    </div>
                    <div className="col-sm-2 col-lg-2 col-md-2 text-right">
                        <Link to="/studentDetail"><button className="btn btn-green" >
                            My List
                        </button></Link>
                    </div>
                </div>
                {isLoading ? (
                    <div className="text-center">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> Loading...</span>
                    </div>
                ) : (
                    <>
                        {user?.length > 0 && !showFavoritesTable && (
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="text-center">
                                            <th>S.no</th>
                                            <th>Name</th>
                                            <th>Education</th>
                                            <th>Contact</th>
                                            <th>Email</th>
                                            {/* <th>Test</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {user?.map((ob, index) => (
                                            <tr key={index} className="text-center">
                                                <td>{index + 1}</td>
                                                <td>{ob.name}</td>
                                                <td>{ob.education}</td>
                                                <td>{ob.contact}</td>
                                                <td>{ob.email}</td>
                                                {/* <td>
                                                    <button
                                                        className="btn btn-center btn-sm btn-primary"
                                                        style={{ borderRadius: "10px" }}
                                                        onClick={() => result(ob.userid)}
                                                    >
                                                        Test History
                                                    </button>
                                                </td> */}
                                                <td className="text-center">

                                                    <button
                                                        className="btn btn-sm btn-secondary text-center"
                                                        style={{ borderRadius: "10px" }}
                                                        onClick={() => addFavorite(loginUser.userId, ob.userid)}
                                                    >
                                                        Make Fav.
                                                    </button>


                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {user?.length === 0 && favorites?.length === 0 && (
                            <div className="text-center">
                                <p>No Records Found </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default withRouter(AddFavorite);
