
import React, { useEffect, useState } from "react";
import QuestionsService from "../../services/QuestionsService";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import { Link } from "react-router-dom";
import ModulesService from "../../services/ModulesService";
// import AdminService from "../../services/AdminService";

function ViewQuestion() {
    const [question, setQuestion] = useState([]);
    const [courselist, setCourseList] = useState([]);
    const [modulelist, setModuleList] = useState([]);

    const [courseId, setCourseId] = useState("");
    const [start, setStart] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false);
    // states for questions update

    const [selectedLevel, setSelectedLevel] = useState("0");

    const [editedQuestion, setEditedQuestion] = useState('');
    const [editedLevel, setEditedLevel] = useState('');
    const [editedModule, setEditedModule] = useState('');
    const [editedStatus, setEditedStatus] = useState('');
    const [editedOption1, setEditedOption1] = useState('');
    const [editedOption2, setEditedOption2] = useState('');
    const [editedOption3, setEditedOption3] = useState('');
    const [editedOption4, setEditedOption4] = useState('');
    const [editedAnswer, setEditedAnswer] = useState('');

    // let courseBox;

    let moduleBox;
    let levelBox;

    const view = (event) => {
        event.preventDefault();

        var module_id = moduleBox.value;
        var quesLevel = levelBox.value === "0" ? 0 : parseInt(levelBox.value); // Compare with integer 0
        // console.log(course_id, module_id, quesLevel);
        QuestionsService.questionByModule(quesLevel, module_id)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                setQuestion(data.question.course);
            });
    };


    const changeLevel = (event) => {
        setSelectedLevel(event.target.value);
    };


    const delques = (ques_id) => {
        // console.log(ques_id)
        const confirmDelete = window.confirm("Are you sure to delete this question ?")
        if (confirmDelete) {
            AdminService.deleteQues(ques_id)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    setQuestion((prevQues) => prevQues.filter(ques => ques._id !== ques_id));
                    setIsDataUpdated(!isDataUpdated)
                })
        }
    }

    const handleUpdateClick = (ques_id) => {
        const selectedQues = question.find((ob) => ob.ques_id === ques_id);
        if (selectedQues) {
            setSelectedQuestion(selectedQues);

            setEditedQuestion(selectedQues.question);
            setEditedLevel(selectedQues.quesLevel);
            setEditedModule(selectedQues.moduleId);
            setEditedStatus(selectedQues.active_status);
            setEditedOption1(selectedQues.option1);
            setEditedOption2(selectedQues.option2);
            setEditedOption3(selectedQues.option3);
            setEditedOption4(selectedQues.option4);
            setEditedAnswer(selectedQues.answer);

            setIsUpdating(true);
        }
    };


    const saveQuestion = async (ques_id) => {
        const updatedQuestions = {
            question: editedQuestion,
            quesLevel: editedLevel,
            moduleId: editedModule,
            active_status: editedStatus,
            option1: editedOption1,
            option2: editedOption2,
            option3: editedOption3,
            option4: editedOption4,
            answer: editedAnswer,
        };

        try {
            await AdminService.updateQuestion(ques_id, updatedQuestions);

            setQuestion((prevQuestions) =>
                prevQuestions.map((ques) =>
                    ques.ques_id === ques_id ? { ...ques, ...updatedQuestions } : ques
                )
            );

            setSelectedQuestion(null);
            setIsUpdating(false);
        } catch (error) {
            // console.log(error);
        }
    };







    const cancelEdit = () => {
        setSelectedQuestion(null);
        setIsUpdating(false);
        setEditedQuestion(selectedQuestion.question);
        setEditedLevel(selectedQuestion.quesLevel);
        setEditedModule(selectedQuestion.moduleId);
        setEditedStatus(selectedQuestion.active_status);
        setEditedOption1(selectedQuestion.option1);
        setEditedOption2(selectedQuestion.option2);
        setEditedOption3(selectedQuestion.option3);
        setEditedOption4(selectedQuestion.option4);
        setEditedAnswer(selectedQuestion.answer);
    };

    useEffect(() => {
        // CoursesService.getAllCoursesList()
        //     .then((response) => response.json())
        //     .then((data) => {
        //         // console.log(data.course);
        //         setCourseList(data.course.course);
        //     });

        ModulesService.getAllModules()
            .then((response) => response.json())
            .then((data) => {
                // console.log(data.course);
                setModuleList(data.module.module);
            });

        QuestionsService.getAllQuestion(start)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                setQuestion(data.question.question);
                setTotalQuestions(data.totalQuestions);
            });
    }, [isDataUpdated]);

    const changeCourse = (event) => {
        setCourseId(event.target.value);
    };

    const preData = (event) => {
        event.preventDefault();
        const s = start - 10;
        setCurrentPage(currentPage - 1);
        QuestionsService.getAllQuestion(s, selectedLevel)
            .then((response) => response.json())
            .then((data) => {
                setQuestion(data.question.question);
                setStart(s);
            });
    };

    const nextData = (event) => {
        event.preventDefault();
        const s = start + 10;
        setCurrentPage(currentPage + 1);
        QuestionsService.getAllQuestion(s, selectedLevel)
            .then((response) => response.json())
            .then((data) => {
                setQuestion(data.question.question);
                setStart(s);
                if (data.question.question.length === 0) {
                    // No more questions, disable next button
                    document.getElementById("nextButton").disabled = true;
                }
            });
    };
    return (
        <>
            <br />
            <br />
            <div className="container">
                <center>
                    <h3>Questions List</h3>
                </center>

                <div className="row col-lg-12 col-md-12 p-4 m-4">
                    {/* <div className="col-lg-4 col-md-5 mb-2">
                        <select
                            className="form-control"
                            onChange={(e) => changeCourse(e)}
                            ref={(c) => (courseBox = c)}
                            required
                        >
                            <option value="">Select Course...</option>
                            {courselist.map((ob, index) => (
                                <option value={ob.course_id} key={index}>
                                    {ob.course_title}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-lg-8 col-md-5 mb-2">
                        <select
                            className="form-control"
                            ref={(c) => (moduleBox = c)}
                            required
                        >
                            <option value="">Select Module...</option>
                            {/* {courselist.find((ob) => ob.course_id == courseId) !== undefined
                                ? courselist
                                    .find((ob) => ob.course_id == courseId)
                                    .module.map((v, index) => (
                                        <option value={v.module_id} key={index}>
                                            {v.module}
                                        </option>
                                    ))
                                : ""} */}

                            {modulelist.map((ob, index) =>
                                <option value={ob.module_id}  >
                                    {ob.module_title}
                                </option>

                            )}
                        </select>
                    </div>
                    <div className="col-lg-2 col-md-2 mb-2">
                        <select className="form-control" ref={(c) => (levelBox = c)} required onChange={changeLevel}>
                            <option value="0">All</option>
                            <option value="1">Level - 1</option>
                            <option value="2">Level - 2</option>
                            <option value="3">Level - 3</option>
                        </select>
                    </div>
                    <div className="col-lg-2 col-md-2 mb-2">
                        <button
                            type="button"
                            onClick={view}

                            className="btn btn-green"
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>

            <br />
            <div className="container  " style={{ width: "100%", maxWidth: "100%" }}>
                <div style={{ overflowX: "scroll" }}>
                    <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                        <table className="table table-bordered table-responsive" style={{ tableLayout: "fixed", width: "100%", overflow: "scroll" }}>
                            <thead>
                                <tr className="text-center">
                                    <th>S.no</th>
                                    <th>Question</th>
                                    <th>Option1</th>
                                    <th>Option2</th>
                                    <th>Option3</th>
                                    <th>Option4</th>
                                    <th>Answer</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>


                            <tbody style={{ overflowX: "scroll" }}>
                                {question.map((ob, index) => {
                                    const ques_id = ob.ques_id;
                                    return (
                                        <tr key={index}>
                                            {selectedQuestion && selectedQuestion.ques_id === ques_id ? (
                                                <>
                                                    <td>{start + index + 1}</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={editedQuestion}
                                                            onChange={(e) => setEditedQuestion(e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={editedOption1}
                                                            onChange={(e) => setEditedOption1(e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={editedOption2}
                                                            onChange={(e) => setEditedOption2(e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={editedOption3}
                                                            onChange={(e) => setEditedOption3(e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={editedOption4}
                                                            onChange={(e) => setEditedOption4(e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            type="text"
                                                            value={editedAnswer}
                                                            onChange={(e) => setEditedAnswer(e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            type="text"
                                                            value={editedStatus}
                                                            onChange={(e) => setEditedStatus(e.target.value)}
                                                        />
                                                    </td>
                                                    <td width="170px">
                                                        <button
                                                            className="btn btn-green btn-block"
                                                            style={{ borderRadius: "10px", marginBottom: "2px" }}
                                                            onClick={() => saveQuestion(ques_id)}
                                                        >
                                                            Save
                                                        </button>{" "}
                                                        &nbsp;
                                                        <button
                                                            className="btn btn-danger btn-block"
                                                            style={{ borderRadius: "10px" }}
                                                            onClick={() => cancelEdit()}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>{start + index + 1}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: ob.question }}></td>
                                                    <td>{ob.option1}</td>
                                                    <td>{ob.option2}</td>
                                                    <td>{ob.option3}</td>
                                                    <td>{ob.option4}</td>
                                                    <td className="text-center">{ob.answer}</td>
                                                    <td className="text-center">{ob.active_status}</td>
                                                    <td width="170px">
                                                        <button
                                                            className="btn btn-sm btn-secondary btn-block"
                                                            style={{ borderRadius: "10px", marginBottom: "2px" }}
                                                            onClick={() => handleUpdateClick(ques_id)}
                                                        >
                                                            Update
                                                        </button>{" "}
                                                        &nbsp;
                                                        <button
                                                            className="btn btn-sm btn-danger btn-block"
                                                            style={{ borderRadius: "10px" }}
                                                            onClick={() => delques(ques_id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>



                        </table>
                    </div>
                </div>
                <div className="container d-flex justify-content-between pagination-container">
                    <button
                        type="button"
                        onClick={preData}
                        className="btn btn-dark ml-5 mb-5"
                        disabled={start === 0 || selectedLevel !== "0"} // Disable the previous button when on the first page or when a specific level is selected
                    >
                        &larr; Previous
                    </button>
                    <div>Page {currentPage}</div>
                    <button
                        type="button"
                        onClick={nextData}
                        id="nextButton"
                        className="btn btn-dark float-right mr-5 mb-5"
                        disabled={selectedLevel !== "0"} // Disable the next button when a specific level is selected
                    >
                        Next &rarr;
                    </button>
                </div>
            </div>
        </>
    );
}

export default ViewQuestion;



// import React, { useEffect, useState } from "react";
// import QuestionsService from "../../services/QuestionsService";
// import CoursesService from "../../services/CoursesService";
// import { Link } from "react-router-dom";
// import AdminService from "../../services/AdminService";

// function ViewQuestion() {
//     const [question, setQuestion] = useState([]);
//     const [courselist, setCourseList] = useState([]);
//     const [courseId, setCourseId] = useState("");
//     const [start, setStart] = useState(0);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalQuestions, setTotalQuestions] = useState(0);
//     const [isDataUpdated, setIsDataUpdated] = useState(false);
//     const [selectedQuestion, setSelectedQuestion] = useState(null)
//     const [isUpdating, setIsUpdating] = useState(false);
//     // states for questions update
//     const [q, setQ] = useState('');
//     const [level, setLevel] = useState('');
//     const [module, setModule] = useState('');
//     const [status, setStatus] = useState('');
//     const [option1, setOption1] = useState('');
//     const [option2, setOption2] = useState('');
//     const [option3, setOption3] = useState('');
//     const [option4, setOption4] = useState('');
//     const [ranswer, setRAnswer] = useState('');
//     var courseBox = undefined;
//     var moduleBox = undefined;
//     var levelBox = undefined;

//     const view = (event) => {
//         event.preventDefault();
//         var course_id = courseBox.value;
//         var module_id = moduleBox.value;
//         var quesLevel = levelBox.value === "0" ? 0 : parseInt(levelBox.value); // Compare with integer 0
//         console.log(course_id, module_id, quesLevel);
//         QuestionsService.getAllQuestionById(course_id, quesLevel, module_id)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setQuestion(data.question.course);
//             });
//     };


//     const delques = (ques_id) => {
//         console.log(ques_id)
//         const confirmDelete = window.confirm("Are you sure to delete this question ?")
//         if (confirmDelete) {
//             AdminService.deleteQues(ques_id)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     console.log(data);
//                     setQuestion((prevQues) => prevQues.filter(ques => ques._id !== ques_id));
//                     setIsDataUpdated(!isDataUpdated)
//                 })
//         }
//     }

//     const handleUpdateClick = (ques_id) => {
//         const selectedQuestion = question.find((ob) => ob.ques_id === ques_id);
//         if (selectedQuestion) {
//             setSelectedQuestion(ques_id);

//             setQ(selectedQuestion.question);
//             setLevel(selectedQuestion.quesLevel);
//             setModule(selectedQuestion.moduleId);
//             setStatus(selectedQuestion.active_status);
//             setOption1(selectedQuestion.option1);
//             setOption2(selectedQuestion.option2);
//             setOption3(selectedQuestion.option3);
//             setOption4(selectedQuestion.option4);
//             setRAnswer(selectedQuestion.answer);
//             setQuestion(selectedQuestion);
//             setIsUpdating(true);
//             alert(selectedQuestion.question)
//         }
//     };
//     // const upQuestion = (ques) => {
//     //     setSelectedQuestion(ques)
//     // }
//     useEffect(() => {
//         CoursesService.getAllCoursesList()
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data.course);
//                 setCourseList(data.course.course);
//             });

//         QuestionsService.getAllQuestion(start)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setQuestion(data.question.question);
//                 setTotalQuestions(data.totalQuestions);
//             });
//     }, [isDataUpdated]);

//     const changeCourse = (event) => {
//         setCourseId(event.target.value);
//     };

//     const preData = (event) => {
//         event.preventDefault();
//         const s = start - 10;
//         setCurrentPage(currentPage - 1);
//         QuestionsService.getAllQuestion(s)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setQuestion(data.question.question);
//                 setStart(s);
//             });
//     };

//     const nextData = (event) => {
//         event.preventDefault();
//         const s = start + 10;
//         setCurrentPage(currentPage + 1);
//         QuestionsService.getAllQuestion(s)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setQuestion(data.question.question);
//                 setStart(s);
//                 if (data.question.question.length === 0) {
//                     // No more questions, disable next button
//                     document.getElementById("nextButton").disabled = true;
//                 }
//             });
//     };

//     return (
//         <>
//             <br />
//             <br />
//             <div className="container">
//                 <center>
//                     <h2>Questions List</h2>
//                 </center>

//                 <div className="row col-lg-12 col-md-12 p-4 m-4">
//                     <div className="col-lg-4 col-md-5 mb-2">
//                         <select
//                             className="form-control"
//                             onChange={(e) => changeCourse(e)}
//                             ref={(c) => (courseBox = c)}
//                             required
//                         >
//                             <option value="">Select Course...</option>
//                             {courselist.map((ob, index) => (
//                                 <option value={ob.course_id} key={index}>
//                                     {ob.course_title}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>
//                     <div className="col-lg-4 col-md-5 mb-2">
//                         <select
//                             className="form-control"
//                             ref={(c) => (moduleBox = c)}
//                             required
//                         >
//                             <option value="">Select Module...</option>
//                             {courselist.find((ob) => ob.course_id == courseId) !== undefined
//                                 ? courselist
//                                     .find((ob) => ob.course_id == courseId)
//                                     .module.map((v, index) => (
//                                         <option value={v.module_id} key={index}>
//                                             {v.module}
//                                         </option>
//                                     ))
//                                 : ""}
//                         </select>
//                     </div>
//                     <div className="col-lg-2 col-md-2 mb-2">
//                         <select className="form-control" ref={(c) => (levelBox = c)} required>
//                             <option value="0">All</option>
//                             <option value="1">Level - 1</option>
//                             <option value="2">Level - 2</option>
//                             <option value="3">Level - 3</option>
//                         </select>
//                     </div>
//                     <div className="col-lg-2 col-md-2 mb-2">
//                         <button
//                             type="button"
//                             onClick={view}
//                             className="btn btn-primary"
//                         >
//                             View
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             <br />
//             <div className="container  " style={{ width: "100%", maxWidth: "100%" }}>
//                 <div style={{ overflowX: "scroll" }}>
//                     <div style={{ maxWidth: "100%", overflowX: "auto" }}>
//                         <table className="table table-bordered table-responsive" style={{ tableLayout: "fixed", width: "100%", overflow: "scroll" }}>
//                             <thead>
//                                 <tr className="text-center">
//                                     <th>S.no</th>
//                                     <th>Question</th>
//                                     <th>Option1</th>
//                                     <th>Option2</th>
//                                     <th>Option3</th>
//                                     <th>Option4</th>
//                                     <th>Answer</th>
//                                     <th>Status</th>
//                                     <th>Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody style={{ overflowX: "scroll" }}>
//                                 {question.map((ob, index) => (
//                                     <tr key={index}>
//                                         <td>{start + index + 1}</td>
//                                         <td>{ob.question}</td>
//                                         <td>{ob.option1}</td>
//                                         <td>{ob.option2}</td>
//                                         <td>{ob.option3}</td>
//                                         <td>{ob.option4}</td>
//                                         <td className="text-center">{ob.answer}</td>
//                                         <td className="text-center">{ob.active_status}</td>
//                                         <td width="170px">
//                                             <Link to={'/SaveQuestions/' + ob.ques_id}><button
//                                                 className="btn btn-secondary btn-block"
//                                                 style={{ borderRadius: "10px", marginBottom: "2px" }}
//                                                 onClick={() => handleUpdateClick(ob.ques_id)}
//                                             >
//                                                 Update
//                                             </button></Link>{" "}
//                                             &nbsp;
//                                             <button
//                                                 className="btn btn-danger btn-block"
//                                                 style={{ borderRadius: "10px" }}
//                                                 onClick={() => delques(ob.ques_id)} // Pass ob.ques_id as the argument
//                                             >
//                                                 Delete
//                                             </button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//                 <div className="container d-flex justify-content-between pagination-container">
//                     <button
//                         type="button"
//                         onClick={preData}
//                         className="btn btn-dark ml-5 mb-5"
//                         disabled={start === 0} // Disable the previous button when on the first page
//                     >
//                         &larr; Previous
//                     </button>
//                     <div>Page {currentPage}</div> {/* Display the current page number */}
//                     <button
//                         type="button"
//                         onClick={nextData}
//                         id="nextButton"
//                         className="btn btn-dark float-right mr-5 mb-5"
//                     >
//                         Next &rarr;
//                     </button>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default ViewQuestion;


// jhdsbvuyerigjeeermv---------------------------------------------------------------------------------------------------------------

