import React from 'react'
import QuestionsService from '../../services/QuestionsService'

import TestService from '../../services/TestService'
import ModulesService from '../../services/ModulesService'
import { Link } from 'react-router-dom'
import withRouter from '../withRouter'

import { useEffect, useState } from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import '../question/QuestionItem.css'

function History(props) {
  const loginUser = useSelector(state => state.user.value)
  // const testans = useSelector(state => state.user.value)
  // var dispatch = useDispatch()

  const [testing, setTest] = useState([])
  const [color, setColor] = useState('')
  const [answer, setSelectAnswer] = useState()
  const [module, setModule] = useState([])



  let { testid } = useParams()

  useEffect(() => {
    TestService.getTestByTestId(testid)
      .then(response => response.json())
      .then(data => {
        setTest(data.test.test)
        // console.log('testdata', data)
      })

    TestService.
      getModuleByTestId(testid)
      .then(response => response.json())
      .then(data =>
      // console.log('dattttttttttttttttttttttta', data),
      {
        setModule(data.moduletitle.module)
        // console.log('modulellllllllllllllll', data)
        // console.log(data)
      }
      )
  }, [])

  return (
    <>
      <div>
        <br></br>
        <br></br>
        <div className=' col-lg-12 col-md-12 col-sm-12'>
          <div className='container'>
            {/* <div className='row'> */}
            <form>
              {/* <div className='module sticky-top col-lg-12'>
                  MODULE : &nbsp;
                  {module.map(ob => ob.module_title) }
                  <div className='queqty'>Total:&nbsp; {questions.length}</div>
                </div> */}
              <div className='module sticky-top col-lg-12'>
                MODULE : &nbsp;
                {module.map(m => m.module_title)}
              </div>
              {testing.map((ob, index) => {
                {/* console.log(ob) */}
                return (
                  <>
                    <div className='box'>
                      <div>
                        Q.&nbsp;{index + 1}
                        &nbsp;
                        {ob.question}
                      </div>
                      <br />
                      <div style={{ borderRadius: "8px", backgroundColor: ob.correctAns == 'a' ? 'rgb(60, 179, 113)' : '' || ob.answer == 'a' ? '#F75D59' : '' }}>
                        &nbsp;
                        {ob.option1}
                      </div>
                      <br />
                      <div style={{ borderRadius: "8px", backgroundColor: ob.correctAns == 'b' ? 'rgb(60, 179, 113)' : '' || ob.answer == 'b' ? '#F75D59' : '' }}>
                        &nbsp;
                        {ob.option2}
                      </div>
                      <br />
                      <div style={{ borderRadius: "8px", backgroundColor: ob.correctAns == 'c' ? 'rgb(60, 179, 113)' : '' || ob.answer == 'c' ? '#F75D59' : '' }}>
                        &nbsp;
                        {ob.option3}
                      </div>
                      <br />
                      <div style={{ borderRadius: "8px", backgroundColor: ob.correctAns == 'd' ? 'rgb(60, 179, 113)' : '' || ob.answer == 'd' ? '#F75D59' : '' }}>
                        &nbsp;
                        {ob.option4}
                      </div>
                      <br />
                    </div>
                  </>
                )
              })}
              {/* </div> */}
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}
export default withRouter(History)
