import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeData } from '../reduxdata/UserSlice';
import RegisterService from '../services/RegisterService';
import './Login.css';
import { Navigate, useNavigate } from 'react-router-dom';

export default function ForgotPassword() {
    const loginUser = useSelector(state => state.user.value); // Assuming this includes user ID
    const [msg, setMsg] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let oldPasswordBox, newPasswordBox, confirmNewPasswordBox = undefined;

    const handlePasswordUpdate = async event => {
        event.preventDefault();
        // setMsg('Updating password ...');

        const oldPassword = oldPasswordBox.value;
        const newPassword = newPasswordBox.value;
        const confirmNewPassword = confirmNewPasswordBox.value;

        if (newPassword !== confirmNewPassword) {
            setMsg('New password and confirm new password do not match.');
            return;
        }

        const passwordData = {
            oldPassword,
            newPassword
        };

        try {
            const response = await RegisterService.updatePassword(loginUser.userId, passwordData);
            console.log(response)
            if (!response.ok) {
                throw new Error(`${response.statusText}`);
            }
            const data = await response.json();
            setMsg(data.message);

            // Display alert with the success message
            alert(`${data.message}: Please login again to continue...`);

            // Dispatch logout action
            dispatch(changeData({ islogin: false }));

            // Redirect to login page
            navigate('/login');

            // alert(data.message);
        } catch (err) {
            setMsg(`An error occurred: ${err.message}`);
            // alert(`An error occurred: ${err.message}`);
        }
    };

    return (
        <div>
            <br />
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="">
                            <h3 className='text-center'>Update Password</h3>
                            <form onSubmit={handlePasswordUpdate}>
                                <div className="">
                                    <div className="col-lg-6 col-12 offset-lg-3 col-md-12 col-sm-12">
                                        <p>Enter your old password and your new password twice to confirm.</p>
                                        <label className="labels mb-0"><b>Old Password</b></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            ref={c => (oldPasswordBox = c)}
                                            name="oldPassword"
                                            placeholder="Old Password"
                                            id="oldPassword"
                                        />
                                        <br />
                                        <label className="labels mb-0"><b>New Password</b></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            ref={c => (newPasswordBox = c)}
                                            name="newPassword"
                                            placeholder="New Password"
                                            id="newPassword"
                                        />
                                        <br />
                                        <label className="labels mb-0"><b>Confirm New Password</b></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            ref={c => (confirmNewPasswordBox = c)}
                                            name="confirmNewPassword"
                                            placeholder="Confirm New Password"
                                            id="confirmNewPassword"
                                        />
                                    </div>
                                    <br />
                                </div>
                                <br />
                                <div className="col-lg-6 offset-lg-3 col-md-8 col-sm-8 text-center">
                                    <button type="submit" className="form-control submit-bt text-center">
                                        Update Password
                                    </button>
                                    {msg && <b className={msg.includes('success') ? 'text-success' : 'text-danger'}>{msg}</b>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
