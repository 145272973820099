class QuestionsService {
  getQuestionById(quesLevel, moduleId, userid) {
    let params = {
      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),
      userid: userid
    }
    // console.log('>>>>>>>>>>>>>>>>>> ', params)
    return fetch('https://backend.skilledfresher.in/api/question/getQuestionById', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }


  getAllQuestionById(course_id, quesLevel, moduleId) {
    let params = {
      course_id: parseInt(course_id),
      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),

    }
    // console.log('>>>>>>>>>>>>>>>>>> ', params)
    return fetch('https://backend.skilledfresher.in/api/question/getAllQuestionById', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }

  questionByModule(quesLevel, moduleId) {
    let params = {

      quesLevel: parseInt(quesLevel),
      moduleId: parseInt(moduleId),

    }
    // console.log('>>>>>>>>>>>>>>>>>> ', params)
    return fetch('https://backend.skilledfresher.in/api/question/questionbymodule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
  }


  // getAllQuestion(start) {
  //   // console.log ('tokennnnnnn', token);
  //   return fetch('https://backend.skilledfresher.in/api/question/list/' + start);
  // }

  getAllQuestion(start) {
    return fetch('https://backend.skilledfresher.in/api/question/list/' + start);
  }
}
export default new QuestionsService()
