import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import withRouter from "../withRouter";
import "./Admin.css";

function SaveCourse(props) {
  const [savecourse, setSaveCourse] = useState([]);
  const [courselist, setCourseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [msg, setMsg] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [description, setDescription] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  var coursebox = undefined;
  var descbox = undefined;
  var statusbox = undefined;

  const save = (event) => {
    event.preventDefault();
    var course_title = event.target.coursebox.value;
    var description = event.target.descbox.value;
    var active_status = event.target.statusbox.value;
    var ob = { course_title, description, active_status };

    AdminService.addCourse(ob)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setMsg("Course Saved..");
        setCourseList((prevCourseList) => [...prevCourseList, data]);
        setIsDataUpdated(!isDataUpdated);
        event.target.reset();
      })
      .catch((error) => {
        setMsg("saving failed..");
      });
  };

  const delcourse = (course_id) => {
    // console.log("sv", course_id);
    const confirmDelete = window.confirm(
      "Are you sure to want to delete this course with their associated modules ?"
    );
    if (confirmDelete) {
      AdminService.deleteCourse(course_id)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          setMsg("Course Deleted..");
          // Update the course list after deletion
          setCourseList((prevCourseList) =>
            prevCourseList.filter((course) => course._id !== course_id)
          );
          setIsDataUpdated(!isDataUpdated); // Trigger data update
        });
    }
  };

  const updateCourseData = (event) => {
    event.preventDefault();
    if (selectedCourse) {
      var updatedCourse = {
        course_id: selectedCourse.course_id,
        course_title: courseTitle,
        description: description,
        active_status: activeStatus,
      };

      // console.log(updatedCourse);
      AdminService.updateCourse(selectedCourse.course_id, updatedCourse)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          setMsg("Course Updated..");
          // Update the course directly in the state
          setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
              course.course_id === selectedCourse.course_id ? data : course
            )
          );
          setIsDataUpdated(!isDataUpdated); // Trigger data update

          setSelectedCourse(null);
          setCourseTitle("");
          setDescription("");
          setActiveStatus("");

          // Assign the updated course to the data variable
          data = {
            ...selectedCourse,
            course_title: courseTitle,
            description: description,
            active_status: activeStatus,
          };

          // console.log(data);
        })
        .catch((error) => {
          setMsg("Update failed..");
        });
    }
  };



  const cancelUpdate = () => {
    setSelectedCourse(null);
    setCourseTitle("");
    setDescription("");
    setActiveStatus("");
    setIsUpdating(false);
  };

  const upCourse = (course) => {
    setSelectedCourse(course);
    setCourseTitle(course.course_title);
    setDescription(course.description);
    setActiveStatus(course.active_status);
    setIsUpdating(true);
  };

  useEffect(() => {
    CoursesService.getAllCourses()
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.course.course);
        setCourseList(data.course.course);
      });
  }, [isDataUpdated]);

  useEffect(() => {
    if (isDataUpdated) {
      setMsg("");
      setSelectedCourse(null);
      setCourseTitle("");
      setDescription("");
      setActiveStatus("");
      setIsUpdating(false);
    }
  }, [isDataUpdated]);

  // Pagination
  const indexOfLastCourse = currentPage * perPage;
  const indexOfFirstCourse = indexOfLastCourse - perPage;
  const currentCourses = courselist.slice(indexOfFirstCourse, indexOfLastCourse);

  const nextData = () => {
    setCurrentPage(currentPage + 1);
  };

  const preData = () => {
    setCurrentPage(currentPage - 1);
  };


  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(courselist.length / perPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <>
      <br></br>
      <br></br>
      <div className="container">
        <form onSubmit={isUpdating ? updateCourseData : save}>
          <center>
            <h3>Add Courses</h3>
          </center>
          <br></br>
          <br></br>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Course</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="coursebox"
                  className="form-control"
                  id="coursebox"
                  placeholder="Enter Course"
                  required
                  value={courseTitle}
                  onChange={(event) => setCourseTitle(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="descbox"
                  className="form-control"
                  id="descbox"
                  placeholder="Enter Description..."
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Active-Status</label>
              &nbsp;&nbsp;&nbsp;
              <select
                className="form-control col-sm-2 col-form-label"
                name="statusbox"
                id="statusbox"
                value={activeStatus}
                onChange={(event) => setActiveStatus(event.target.value)}
              >
                <option value="1">true</option>
                <option value="0">false</option>
              </select>
              <div className="col-sm-2 col-lg-2">
                {isUpdating && selectedCourse ? (
                  <>
                    <button
                      type="submit"
                      className="btn btn-green"
                      style={{ borderRadius: "10px" }}
                    >
                      Save
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={cancelUpdate}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ borderRadius: "10px" }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>

            &nbsp;

            <b className="text-danger">{msg}</b>
          </div>
        </form>
      </div>
      <br />
      <div className="container">
        <div className="table-responsive">
          <table className="table table-bordered table-fixed ">
            <thead>
              <tr className="text-center">
                <th className="fixed-header">S.no</th>
                <th className="fixed-header">Course Name</th>
                <th className="fixed-header">Description</th>
                <th className="fixed-header">Active-Status</th>
                <th className="fixed-header">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentCourses.map((ob, index) => (
                <tr key={index}>
                  <td>{index + 1 + (currentPage - 1) * perPage}</td>
                  <td>{ob.course_title}</td>
                  <td>{ob.description}</td>
                  <td className="text-center">{ob.active_status}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={() => upCourse(ob)}
                    >
                      Update
                    </button>
                    &nbsp;

                    <button
                      className="btn btn-sm btn-danger d-flex"
                      style={{ borderRadius: "10px" }}
                      onClick={() => delcourse(ob.course_id)}
                    >
                      Delete
                    </button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="container d-flex justify-content-between pagination-container">
          <button
            type="button"
            className="btn btn-dark"
            onClick={preData}
            disabled={currentPage === 1}
          >
            &larr; Previous
          </button>
          {/* <div>Page {currentPage}</div> */}
          {/* <div>Page {currentPage} of {Math.ceil(courselist.length / perPage)}</div> */}
          <div>
            {getPageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                type="button"
                className={`btn btn-dark mr-1 ${currentPage === pageNumber ? 'active' : ''}`}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
          </div>
          <button
            type="button"
            className="btn btn-dark"
            onClick={nextData}
            disabled={indexOfLastCourse >= courselist.length}
          >
            Next &rarr;
          </button>
        </div>
      </div>
    </>
  );
}

export default withRouter(SaveCourse);





// import React, { useEffect, useState } from "react";
// import AdminService from "../../services/AdminService";
// import CoursesService from "../../services/CoursesService";
// import withRouter from "../withRouter";
// import './Admin.css';


// function SaveCourse(props) {
//   const [savecourse, setSaveCourse] = useState([]);
//   const [courselist, setCourseList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage] = useState(10);
//   const [msg, setMsg] = useState('');
//   const [isDataUpdated, setIsDataUpdated] = useState(false);
//   const [selectedCourse, setSelectedCourse] = useState(null);
//   const [courseTitle, setCourseTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [activeStatus, setActiveStatus] = useState("");
//   const [isUpdating, setIsUpdating] = useState(false);


//   var coursebox = undefined;
//   var descbox = undefined;
//   var statusbox = undefined;

//   const save = (event) => {
//     event.preventDefault();
//     var course_title = event.target.coursebox.value;
//     var description = event.target.descbox.value;
//     var active_status = event.target.statusbox.value;
//     var ob = { course_title, description, active_status };

//     AdminService.addCourse(ob)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         setMsg("Course Saved..");
//         setCourseList((prevCourseList) => [...prevCourseList, data])
//         setIsDataUpdated(!isDataUpdated)
//         event.target.reset();

//       }).catch((error) => {
//         setMsg('saving failed..')
//       })
//   };



//   const delcourse = (course_id) => {
//     console.log("sv", course_id)
//     const confirmDelete = window.confirm("Are you sure to want to delete this course with theire associated modules ?");
//     if (confirmDelete) {
//       AdminService.deleteCourse(course_id)
//         .then((response) => response.json())
//         .then((data) => {
//           console.log(data);
//           setMsg("Course Deleted..");
//           // Update the course list after deletion
//           setCourseList((prevCourseList) => prevCourseList.filter(course => course._id !== course_id));
//           setIsDataUpdated(!isDataUpdated); // Trigger data update

//         });
//     }
//   };

//   const updateCourseData = (event) => {
//     event.preventDefault();
//     if (selectedCourse) {
//       var updatedCourse = {
//         course_id: selectedCourse.course_id,
//         course_title: courseTitle,
//         description: description,
//         active_status: activeStatus,
//       };

//       console.log(updatedCourse);
//       AdminService.updateCourse(selectedCourse.course_id, updatedCourse)
//         .then((response) => response.json())
//         .then((data) => {
//           console.log(data);
//           setMsg("Course Updated..");
//           // Update the course directly in the state
//           setCourseList((prevCourseList) =>
//             prevCourseList.map((course) =>
//               course.course_id === selectedCourse.course_id ? data : course
//             )
//           );
//           setIsDataUpdated(!isDataUpdated); // Trigger data update

//           setSelectedCourse(null);
//           setCourseTitle("");
//           setDescription("");
//           setActiveStatus("");

//           // Assign the updated course to the data variable
//           data = {
//             ...selectedCourse,
//             course_title: courseTitle,
//             description: description,
//             active_status: activeStatus
//           };

//           console.log(data);
//         })
//         .catch((error) => {
//           setMsg("Update failed..");
//         });
//     }
//   };

//   const cancelUpdate = () => {
//     setSelectedCourse(null);
//     setCourseTitle("");
//     setDescription("");
//     setActiveStatus("");
//     setIsUpdating(false);
//   };


//   const upCourse = (course) => {
//     setSelectedCourse(course);
//     setCourseTitle(course.course_title);
//     setDescription(course.description);
//     setActiveStatus(course.active_status);
//     setIsUpdating(true);
//   };

//   useEffect(() => {
//     CoursesService.getAllCourses()
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data.course.course);
//         setCourseList(data.course.course);
//       });
//   }, [isDataUpdated]);

//   // Pagination
//   const indexOfLastCourse = currentPage * perPage;
//   const indexOfFirstCourse = indexOfLastCourse - perPage;
//   const currentCourses = courselist.slice(indexOfFirstCourse, indexOfLastCourse);

//   const nextData = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const preData = () => {
//     setCurrentPage(currentPage - 1);
//   };


//   return (
//     <>
//       <br></br>
//       <br></br>
//       <div className="container">



//         <form onSubmit={isUpdating ? updateCourseData : save}>
//           <center><h2>Add Courses</h2></center>
//           <br></br>

//           <br></br>
//           <div className="col-lg-12 col-sm-12 col-md-12">
//             <div className="form-group row">

//               <label className="col-sm-2 col-form-label">Course</label>
//               <div className="col-sm-10">
//                 <input
//                   type="text"
//                   name="coursebox"
//                   className="form-control"
//                   id="coursebox"
//                   placeholder="Enter Course"
//                   required
//                   value={courseTitle}
//                   onChange={(event) => setCourseTitle(event.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="form-group row">
//               <label className="col-sm-2 col-form-label">Description</label>
//               <div className="col-sm-10">
//                 <input
//                   type="text"
//                   name="descbox"
//                   className="form-control"
//                   id="descbox"
//                   placeholder="Enter Description..."
//                   value={description}
//                   onChange={(event) => setDescription(event.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="form-group row">
//               <label className="col-sm-2 col-form-label">Active-Status</label>
//               &nbsp;&nbsp;&nbsp;
//               <select
//                 className="form-control col-sm-2 col-form-label"
//                 name="statusbox"
//                 id="statusbox"
//                 value={activeStatus}
//                 onChange={(event) => setActiveStatus(event.target.value)}
//               >
//                 <option value="1">true</option>
//                 <option value="0">false</option>
//               </select>
//             </div>
//             &nbsp;
//             <div className="col-sm-2 col-lg-2">
//               {isUpdating ? (
//                 <>
//                   <button
//                     type="submit"
//                     className="btn btn-success"
//                     style={{ borderRadius: "10px" }}
//                   >
//                     Save
//                   </button>
//                   &nbsp;
//                   <button
//                     className="btn btn-secondary"
//                     style={{ borderRadius: "10px" }}
//                     onClick={cancelUpdate}
//                   >
//                     Cancel
//                   </button>
//                 </>
//               ) : (
//                 <button
//                   type="submit"
//                   className="btn btn-primary"
//                   style={{ borderRadius: "10px" }}
//                 >
//                   Save
//                 </button>
//               )}
//             </div>
//             <b className="text-danger">{msg}</b>

//           </div>

//           {/* </div> */}
//         </form >

//       </div >

//       <br />
//       <div className="container">
//         <table className="table table-bordered table-fixed ">
//           <thead>
//             <tr className="text-center">
//               <th className="fixed-header">S.no</th>
//               <th className="fixed-header">Course Name</th>
//               <th className="fixed-header">Description</th>
//               <th className="fixed-header">Active-Status</th>
//               <th className="fixed-header" >Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentCourses.map((ob, index) => (
//               <tr key={index}>
//                 <td>{index + 1 + (currentPage - 1) * perPage}</td>
//                 <td>{ob.course_title}</td>
//                 <td>{ob.description}</td>
//                 <td className="text-center">{ob.active_status}</td>

//                 <td>
//                   {/* {selectedCourse && selectedCourse.course_id === ob.course_id ? (
//                     <>
//                       <button
//                         className="btn btn-success"
//                         style={{ borderRadius: "10px" }}
//                         onClick={updateCourseData}
//                       >
//                         Save
//                       </button>
//                       &nbsp;
//                       <button
//                         className="btn btn-secondary"
//                         style={{ borderRadius: "10px" }}
//                         onClick={() => setSelectedCourse(null)}
//                       >
//                         Cancel
//                       </button>
//                     </>
//                   ) : (*/}
//                   <button
//                     className="btn btn-secondary"
//                     style={{ borderRadius: "10px" }}
//                     onClick={() => upCourse(ob)}
//                   >
//                     Update
//                   </button>
//                   {/* )}
//                   &nbsp; */}
//                   <button
//                     className="btn btn-danger"
//                     style={{ borderRadius: "10px" }} onClick={() => delcourse(ob.course_id)}
//                   >
//                     Delete
//                   </button>

//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <div className="container d-flex justify-content-between">
//           <button
//             type="button"
//             className="btn btn-dark"
//             onClick={preData}
//             disabled={currentPage === 1}
//           >
//             &larr; Previous
//           </button>
//           <div>Page {currentPage}</div>
//           <button
//             type="button"
//             className="btn btn-dark"
//             onClick={nextData}
//             disabled={indexOfLastCourse >= courselist.length}
//           >
//             Next &rarr;
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }
// export default withRouter(SaveCourse);









