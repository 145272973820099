



import React, { useState, useEffect } from 'react';
import TestService from '../../services/TestService';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function TestHistory(props) {
  const [testByUser, setTestByUser] = useState([]);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  const loginUser = useSelector(state => state.user.value);

  useEffect(() => {
    const userid = loginUser.userId;

    TestService.getTestAnswer(userid)
      .then(response => response.json())
      .then(data => {
        setTestByUser(data.testHistory.test);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortDirection === 'asc' ? <span>&#x25B2;</span> : <span>&#x25BC;</span>;
    }
    return <span>&#x2195;</span>; // Double arrow icon indicating sortable column
  };

  const sortedTestByUser = testByUser.sort((a, b) => {
    if (sortColumn === 'testid') {
      return sortDirection === 'asc'
        ? a.testid - b.testid
        : b.testid - a.testid;
    } else if (sortColumn === 'date') {
      return sortDirection === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (sortColumn === 'module_title') {
      return sortDirection === 'asc'
        ? a.module_title.localeCompare(b.module_title)
        : b.module_title.localeCompare(a.module_title);
    } else if (sortColumn === 'quesLevel') {
      return sortDirection === 'asc'
        ? parseInt(a.quesLevel) - parseInt(b.quesLevel)
        : parseInt(b.quesLevel) - parseInt(a.quesLevel);
    }
    return 0;
  });

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 ">
        <h3 className="text-center mb-5 mt-5">Test-History!</h3>

        <div className="container">
          <div className="row">
            <div className="table-responsive">
              <table className="table col-lg-12 col-sm-12 col-md-12">
                <thead>
                  <tr className="text-center">
                    <th>S.no</th>
                    {/* <th onClick={() => handleSort('testid')}>
                      #Test {renderSortArrow('testid')}
                    </th> */}
                    <th onClick={() => handleSort('date')}>
                      Date {renderSortArrow('date')}
                    </th>
                    <th onClick={() => handleSort('module_title')}>
                      Module {renderSortArrow('module_title')}
                    </th>
                    <th onClick={() => handleSort('quesLevel')}>
                      Level {renderSortArrow('quesLevel')}
                    </th>
                    <th>Total question</th>
                    <th>Attempted</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedTestByUser.map((ob, index) => (
                    <tr key={index} className="text-center">
                      <td>{index + 1}</td>
                      {/* <td>{ob.testid}</td> */}
                      <td>{formatDate(ob.date)}</td>
                      <td>{ob.module_title}</td>
                      <td>{ob.quesLevel}</td>
                      <td>{ob.totalQ}</td>
                      <td>{ob.totalQues.length}</td>

                      <td>
                        {loginUser.role == "student" ? <>

                          <button
                            className="btn btn-secondary"
                            style={{
                              backgroundColor: '',
                              height: 'auto',
                              width: '60px',
                              borderRadius: '10px'
                            }}
                          >
                            {ob.totalQues.reduce(
                              (x, ob) => (ob.isright === 1 ? x + 1 : x),
                              0
                            )}/{ob.totalQ}
                          </button>
                        </> : <> <Link to={'/History/' + ob.testid}>
                          <button
                            className="btn btn-secondary"
                            style={{
                              backgroundColor: '',
                              height: 'auto',
                              width: '60px',
                              borderRadius: '10px'
                            }}
                          >
                            {ob.totalQues.reduce(
                              (x, ob) => (ob.isright === 1 ? x + 1 : x),
                              0
                            )}/{ob.totalQ}
                          </button>
                        </Link> </>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}





// import React, { Component } from 'react'
// import { useState, useEffect } from 'react'
// import TestService from '../../services/TestService'
// import { useSelector, useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'
// import { changeTestData } from '../../reduxdata/TestSlice'
// // import { useParams } from 'react-router-dom'

// export default function TestHistory(props) {
//   const [testByUser, setTestByUser] = useState([])
//   const [len, setLen] = useState('')
//   const [as, setAs] = useState([])
//   const loginUser = useSelector(state => state.user.value)
//   const Test = useSelector(state => state.user.value)
//   var dispatch = useDispatch()

//   // let { userid } = useParams()
//   useEffect(() => {
//     const userid = loginUser.userId
//     const testid = Test.testid

//     console.log('useri----------d', userid)
//     console.log('test----------d', testid)

//     TestService.getTestAnswer(userid)
//       .then(response => response.json())
//       .then(data => {
//         setTestByUser(data.testHistory.test)
//         console.log('userid', data.userid)
//         console.log('testid', data.testid)
//         console.log('test', data)
//         // console.log('len', data.testHistory.map(o => o.totalQues.totalQ).length)
//         // console.log('tq', ob.totalQ.totalQ)

//         //setLen(data.testHistory.map(o => o.totalQues.length))



//       })


//   }, [])
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = date.getMonth() + 1; // Adjust month index by adding 1
//     const day = date.getDate();
//     return `${day}-${month}-${year}`;
//   };


//   return (
//     <>
//       <div className='col-lg-12 col-md-12 col-sm-12'>
//         <h1 className='text-center mb-5'>Test-Histroy!</h1>

//         <div className='container'>
//           <div className='row'>
//             {/* <div className='msg ' > */}

//             {/* <h2>Your Submission has been recieved !..</h2> */}
//             {/* </div> */}
//             <div className="table-responsive">

//               <table className='table col-lg-12 col-sm-12 col-md-12 '>
//                 <thead>
//                   <tr className="text-center">
//                     <th>S.no</th>
//                     <th>#Test</th>
//                     <th>Date</th>
//                     <th>Module</th>
//                     <th>Level</th>
//                     <th>Total<br></br> question</th>
//                     <th>Attempted</th>
//                     <th>Result</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {testByUser.map((ob, index) => {

//                     return (
//                       <tr key={index} className="text-center">
//                         <td>{index + 1}</td>
//                         <td>{ob.testid}</td>
//                         <td>{formatDate(ob.date)}
//                         </td>
//                         <td>{ob.module_title}</td>
//                         <td >{ob.quesLevel}</td>
//                         <td >{ob.totalQ}</td>
//                         <td >{ob.totalQues.length}</td>
//                         <td>
//                           <Link to={'/History/' + ob.testid}>
//                             {/* <button className='btn btn-secondary' style={{ backgroundColor: "", height: "auto", width: "60px", borderRadius: "10px" }}>res{ob.totalQues.isright}</button> &nbsp;&nbsp; */}
//                             <button className="btn btn-secondary" style={{ backgroundColor: "", height: "auto", width: "60px", borderRadius: "10px" }}>
//                               {ob.totalQues.reduce((x, ob) => ob.isright == 1 ? x + 1 : x, 0)}/{ob.totalQ}
//                             </button>
//                           </Link>
//                         </td>
//                       </tr>
//                     )
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }