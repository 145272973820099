import React, { useEffect, useState } from 'react';
import AdminService from '../../services/AdminService';
import ModulesService from '../../services/ModulesService';
import withRouter from '../withRouter';

function SaveModule(props) {
    const [modulelist, setModuleList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [mmsg, setMmsg] = useState('');
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const [moduleTitle, setModuleTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        ModulesService.getAllModules()
            .then((response) => response.json())
            .then((data) => {
                // console.log(data.module.module);
                setModuleList(data.module.module);
            });
    }, [isDataUpdated]); // Update module list when data is updated

    useEffect(() => {
        if (isDataUpdated) {
            setMmsg("");
            setSelectedModule(null);
            setModuleTitle("");
            setDescription("");
            setActiveStatus("");
            setIsUpdating(false);
        }
    }, [isDataUpdated]);
    const save = (event) => {
        event.preventDefault();
        var module_title = event.target.modulebox.value;
        var description = event.target.descbox.value;
        var active_status = event.target.statusbox.value;
        // console.log(module_title, description, active_status);
        var ob = { module_title, description, active_status };
        AdminService.addModule(ob)
            .then((response) => response.json())
            .then((data) => {
                setMmsg('Module saved..');
                // console.log(data);
                setModuleList((prevModuleList) => [...prevModuleList, data]); // Add new module to the list
                setIsDataUpdated(!isDataUpdated); // Trigger data update
                event.target.reset();
            })
            .catch((error) => {
                setMmsg('Saving failed..');
            });
    };

    const delmodule = (module_id) => {
        // console.log('sv', module_id);
        const confirmDelete = window.confirm('If you want to delete this Module the associated question will also delete..!');
        if (confirmDelete) {
            AdminService.deleteModule(module_id)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    setMmsg('Module Deleted..');
                    setModuleList((prevModuleList) =>
                        prevModuleList.filter((module) => module._id !== module_id)
                    ); // Remove deleted module from the list
                    setIsDataUpdated(!isDataUpdated); // Trigger data update
                });
        }
    };

    const updateModuleData = (event) => {
        event.preventDefault();
        if (selectedModule) {
            var updatedModule = {
                module_id: selectedModule.module_id,
                module_title: moduleTitle,
                description: description,
                active_status: activeStatus,
            };
            // console.log(updatedModule);
            AdminService.updateModule(selectedModule.module_id, updatedModule).then((response) => response.json()).then((data) => {
                // console.log(data)
                setMmsg("Module Updated...");
                setModuleList((prevModuleList) => prevModuleList.map((module) => module.module_id === selectedModule.module_id ? data : module))
                setIsDataUpdated(!isDataUpdated)
                setSelectedModule(null)
                setModuleTitle('');
                setDescription('');
                setActiveStatus('');

                data = { ...selectedModule, module_title: moduleTitle, description: description, active_status: activeStatus }
                // console.log(data)
            }).catch((error) => {
                setMmsg("Update Failed..")
            })

        }

    }

    const cancelUpdate = () => {
        setSelectedModule(null);
        setModuleTitle("");
        setDescription("");
        setActiveStatus("");
        setIsUpdating(false);
    }

    const upModule = (module) => {
        setSelectedModule(module);
        setModuleTitle(module.module_title);
        setDescription(module.description);
        setActiveStatus(module.active_status);
        setIsUpdating(true);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = modulelist.slice(indexOfFirstItem, indexOfLastItem);

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(modulelist.length / itemsPerPage);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    return (
        <>
            <br />
            <br />
            <div className='container'>
                <form onSubmit={isUpdating ? updateModuleData : save}>
                    <center>
                        <h3>Add Modules</h3>
                    </center>
                    <br />
                    <br />
                    <div className='form-group row'>
                        <label className='col-sm-2 col-form-label'>Module</label>
                        <div className='col-sm-10'>
                            <input type='text' name='modulebox' className='form-control' id='modulebox' placeholder='Enter Module' value={moduleTitle} onChange={(event) => setModuleTitle(event.target.value)} required />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-sm-2 col-form-label'>Description</label>
                        <div className='col-sm-10'>
                            <input type='text' name='descbox' className='form-control' id='descbox' placeholder='Enter Description...' value={description}
                                onChange={(event) => setDescription(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-sm-2 col-form-label'>Active-Status</label>
                        &nbsp;&nbsp;&nbsp;
                        <select className='form-control col-sm-2 col-form-label' name='statusbox' id='statusbox' value={activeStatus} onChange={(event) => setActiveStatus(event.target.value)}>
                            <option value='1'>true</option>
                            <option value='0'>false</option>
                        </select>
                        &nbsp;
                        <div className="col-sm-2 col-lg-2">
                            {isUpdating && selectedModule ? (
                                <>
                                    <button
                                        type="submit"
                                        className="btn btn-green"
                                        style={{ borderRadius: "10px" }}
                                    >
                                        Save
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-secondary"
                                        style={{ borderRadius: "10px" }}
                                        onClick={cancelUpdate}
                                    >
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-green"
                                    style={{ borderRadius: "10px" }}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                        &nbsp;
                        <b className='text-danger'>{mmsg}</b>
                    </div>
                </form>
            </div>

            <br />
            <div className='container'>
                <div className="table-responsive">

                    <table className='table table-bordered table-fixed'>
                        <thead>
                            <tr className='text-center'>
                                <th>S.no</th>
                                <th>Module Name</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((ob, index) => (
                                <tr key={index} className='text-center'>
                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                    <td>{ob.module_title}</td>
                                    <td>{ob.description}</td>
                                    <td className='text-center'>{ob.active_status}</td>
                                    <td width='200px'>
                                        <button className='btn btn-sm btn-secondary' style={{ borderRadius: '10px' }} onClick={() => upModule(ob)}>
                                            Update
                                        </button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-danger' style={{ borderRadius: '10px' }} onClick={() => delmodule(ob.module_id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='container d-flex justify-content-between pagination-container'>
                    <button type='button' className='btn btn-dark' onClick={goToPreviousPage} disabled={currentPage === 1}>
                        &larr; Previous
                    </button>
                    {/* <div>Page {currentPage}</div> */}
                    <div>
                        {getPageNumbers().map((pageNumber) => (
                            <button
                                key={pageNumber}
                                type="button"
                                className={`btn btn-dark mr-1 ${currentPage === pageNumber ? 'active' : ''}`}
                                onClick={() => handlePageClick(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                    </div>
                    <button
                        type='button'
                        className='btn btn-dark'
                        onClick={goToNextPage}
                        disabled={indexOfLastItem >= modulelist.length}
                    >
                        Next &rarr;
                    </button>
                </div>
            </div>
        </>
    );
}

export default withRouter(SaveModule);



// import React from 'react'
// import AdminService from '../../services/AdminService'
// import ModulesService from '../../services/ModulesService'
// import { useEffect, useState } from 'react'
// import withRouter from '../withRouter'

// function SaveModule(props) {



//     const [savecourse, setSaveCourse] = useState([])
//     const [modulelist, setModuleList] = useState([])

//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 10;
//     const [mmsg, setMmsg] = useState("")

//     var modulebox = undefined
//     var descbox = undefined
//     var statusbox = undefined
//     // console.log(course_title,description,active_status)
//     const save = (event) => {
//         event.preventDefault();
//         var module_title = modulebox.value
//         var description = descbox.value
//         var active_status = statusbox.value
//         console.log(module_title, description, active_status)
//         var ob = { module_title, description, active_status }
//         AdminService.addModule(ob)
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setMmsg("Module saved..");

//                 // Append the new module to the existing module list
//                 setModuleList([...modulelist, data]);

//                 event.target.reset();
//             })
//             .catch((error) => {
//                 setMmsg("Saving failed..");
//             });
//     }

//     const delmodule = (module_id) => {
//         console.log("sv", module_id)
//         const confirmDelete = window.confirm("Are you sure you want to delete this module?");
//         if (confirmDelete) {
//             AdminService.deleteModule(module_id)
//                 .then((response) => response.json())
//                 .then((data) => {
//                     console.log(data);
//                     setMmsg("Module Deleted..");
//                     // Update the course list after deletion
//                     setModuleList(modulelist.filter(module => module._id !== module_id));
//                 });
//         }
//     };

//     useEffect(() => {
//         ModulesService.getAllModules()
//             .then(response => response.json())
//             .then(data => {
//                 console.log(data.module.module);
//                 setModuleList(data.module.module)

//             })
//     }, [])

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = modulelist.slice(indexOfFirstItem, indexOfLastItem);

//     const goToPreviousPage = () => {
//         setCurrentPage(currentPage - 1);
//     };

//     const goToNextPage = () => {
//         setCurrentPage(currentPage + 1);
//     };

//     return <>
//         <br></br>
//         <br></br>
//         <div className='container'>
//             <form onSubmit={save}>
//                 <center><h2>Add Modules</h2></center>
//                 <br></br>
//                 <br></br>
//                 <div className="form-group row">
//                     <label className="col-sm-2 col-form-label">Module</label>
//                     <div className="col-sm-10">
//                         <input type="text" ref={c => modulebox = c} className="form-control" id="" placeholder="Enter Module" required />
//                     </div>
//                 </div>
//                 <div className="form-group row">
//                     <label className="col-sm-2 col-form-label">Description</label>
//                     <div className="col-sm-10">
//                         <input type="text" ref={c => descbox = c} className="form-control" id="" placeholder="Enter Description..." />
//                     </div>
//                 </div>
//                 <div className="form-group row">
//                     <label className="col-sm-2 col-form-label">Active-Status</label> &nbsp;&nbsp;&nbsp;
//                     <select className="form-control col-sm-2 col-form-label" ref={c => statusbox = c}>
//                         <option value='1'>true</option>
//                         <option value='0'>false</option>
//                     </select>
//                     &nbsp;
//                     <div className="col-sm-2 col-lg-2 ">
//                         <button type="submit" className="btn btn-primary">Save</button>
//                     </div>
//                     &nbsp;<b className='text danger'>{mmsg}</b>
//                 </div>
//                 {/* <div className="form-group row">
//                     <div className="col-sm-10 ">
//                         <button type="submit" className="btn btn-primary">Save</button>
//                     </div>
//                     &nbsp;<b className='text danger'>{mmsg}</b>
//                 </div> */}
//             </form>
//         </div>

//         <br />
//         <div className='container'>
//             <table className="table table-bordered table-fixed  ">
//                 <thead>
//                     <tr className="text-center">
//                         <th>S.no</th>
//                         <th>Module Name</th>
//                         <th>Description</th>
//                         <th >Status</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {currentItems.map((ob, index) => <tr key={index}>
//                         <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
//                         <td>{ob.module_title}</td>
//                         <td>{ob.description}</td>
//                         <td className="text-center">{ob.active_status}</td>

//                         <td width='200px'><button className="btn  btn-secondary" style={{ borderRadius: "10px" }}>Update

//                         </button>&nbsp;
//                             <button className="btn   btn-danger" style={{ borderRadius: "10px" }} onClick={() => delmodule(ob.module_id)}>Delete

//                             </button></td>

//                     </tr>)}
//                 </tbody>
//             </table>
//             <div className="container d-flex justify-content-between">
//                 <button type="button" class="btn btn-dark" onClick={goToPreviousPage} disabled={currentPage === 1}> &larr; Previous</button>
//                 <div>Page {currentPage}</div>
//                 <button type="button" class="btn btn-dark" onClick={goToNextPage} disabled={indexOfLastItem >= modulelist.length}>Next &rarr;</button>
//             </div>
//         </div>
//     </>
// }
// export default withRouter(SaveModule)
